@import url(https://unpkg.com/aos@next/dist/aos.css);
/**
 * FormValidation (https://formvalidation.io)
 * The best validation library for JavaScript
 * (c) 2013 - 2018 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.fv-sr-only {
  display: none; }

.fv-plugins-framework input::-ms-clear,
.fv-plugins-framework textarea::-ms-clear {
  display: none;
  height: 0;
  width: 0; }

.fv-plugins-icon-container {
  position: relative; }

.fv-plugins-icon {
  position: absolute;
  right: 0;
  text-align: center;
  top: 0; }

.fv-plugins-tooltip {
  position: absolute;
  z-index: 10000; }

.fv-plugins-tooltip .fv-plugins-tooltip__content {
  background: #000;
  border-radius: 3px;
  color: #eee;
  padding: 10px;
  position: relative; }

.fv-plugins-tooltip .fv-plugins-tooltip__content:before {
  border: 8px solid transparent;
  content: '';
  position: absolute; }

.fv-plugins-tooltip--hide {
  opacity: 0; }

.fv-plugins-tooltip--top-left {
  margin-top: 8px; }

.fv-plugins-tooltip--top-left .fv-plugins-tooltip__content:before {
  border-top-color: #000;
  left: 8px;
  top: 100%; }

.fv-plugins-tooltip--top {
  margin-top: 8px; }

.fv-plugins-tooltip--top .fv-plugins-tooltip__content:before {
  border-top-color: #000;
  left: 50%;
  margin-left: -8px;
  top: 100%; }

.fv-plugins-tooltip--top-right {
  margin-top: 8px; }

.fv-plugins-tooltip--top-right .fv-plugins-tooltip__content:before {
  border-top-color: #000;
  right: 8px;
  top: 100%; }

.fv-plugins-tooltip--right .fv-plugins-tooltip__content:before {
  border-right-color: #000;
  margin-top: -8px;
  right: 100%;
  top: 50%; }

.fv-plugins-tooltip--bottom-right {
  margin-top: 8px; }

.fv-plugins-tooltip--bottom-right .fv-plugins-tooltip__content:before {
  border-bottom-color: #000;
  bottom: 100%;
  right: 8px; }

.fv-plugins-tooltip--bottom {
  margin-top: 8px; }

.fv-plugins-tooltip--bottom .fv-plugins-tooltip__content:before {
  border-bottom-color: #000;
  bottom: 100%;
  left: 50%;
  margin-left: -8px; }

.fv-plugins-tooltip--bottom-left {
  margin-top: 8px; }

.fv-plugins-tooltip--bottom-left .fv-plugins-tooltip__content:before {
  border-bottom-color: #000;
  bottom: 100%;
  left: 8px; }

.fv-plugins-tooltip--left .fv-plugins-tooltip__content:before {
  border-left-color: #000;
  left: 100%;
  margin-top: -8px;
  top: 50%; }

.fv-plugins-tooltip-icon {
  cursor: pointer;
  pointer-events: inherit; }

.fv-plugins-bootstrap {
  /* For horizontal form */
  /* Stacked form */
  /* Inline form */ }

.fv-plugins-bootstrap .fv-help-block {
  color: #dc3545;
  font-size: 80%;
  margin-top: .25rem; }

.fv-plugins-bootstrap .is-invalid ~ .form-check-label,
.fv-plugins-bootstrap .is-valid ~ .form-check-label {
  color: inherit; }

.fv-plugins-bootstrap .has-danger .fv-plugins-icon {
  color: #dc3545; }

.fv-plugins-bootstrap .has-success .fv-plugins-icon {
  color: #28a745; }

.fv-plugins-bootstrap .fv-plugins-icon {
  height: 38px;
  line-height: 38px;
  width: 38px; }

.fv-plugins-bootstrap .input-group ~ .fv-plugins-icon {
  z-index: 3; }

.fv-plugins-bootstrap .form-group.row .fv-plugins-icon {
  right: 15px; }

.fv-plugins-bootstrap .form-group.row .fv-plugins-icon-check {
  top: -7px;
  /* labelHeight/2 - iconHeight/2 */ }

.fv-plugins-bootstrap:not(.form-inline) label ~ .fv-plugins-icon {
  top: 32px; }

.fv-plugins-bootstrap:not(.form-inline) label ~ .fv-plugins-icon-check {
  top: 25px; }

.fv-plugins-bootstrap:not(.form-inline) label.sr-only ~ .fv-plugins-icon-check {
  top: -7px; }

.fv-plugins-bootstrap.form-inline .form-group {
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: auto; }

.fv-plugins-bootstrap3 .help-block {
  margin-bottom: 0; }

.fv-plugins-bootstrap3 .input-group ~ .form-control-feedback {
  z-index: 4; }

.fv-plugins-bootstrap3.form-inline .form-group {
  vertical-align: top; }

.fv-plugins-bulma {
  /* Support add ons inside field */ }

.fv-plugins-bulma .field.has-addons {
  flex-wrap: wrap; }

.fv-plugins-bulma .field.has-addons::after {
  content: '';
  width: 100%; }

.fv-plugins-bulma .field.has-addons .fv-plugins-message-container {
  order: 1; }

.fv-plugins-bulma .icon.fv-plugins-icon-check {
  top: -4px; }

.fv-plugins-bulma .fv-has-error .input,
.fv-plugins-bulma .fv-has-error .textarea {
  border: 1px solid #ff3860;
  /* Same as .input.is-danger */ }

.fv-plugins-bulma .fv-has-success .input,
.fv-plugins-bulma .fv-has-success .textarea {
  border: 1px solid #23d160;
  /* Same as .input.is-success */ }

.fv-plugins-foundation {
  /* Stacked form */ }

.fv-plugins-foundation .fv-plugins-icon {
  height: 39px;
  line-height: 39px;
  right: 0;
  width: 39px;
  /* Same as height of input */ }

.fv-plugins-foundation .grid-padding-x .fv-plugins-icon {
  right: 15px; }

.fv-plugins-foundation .fv-plugins-icon-container .cell {
  position: relative; }

.fv-plugins-foundation [type="checkbox"] ~ .fv-plugins-icon,
.fv-plugins-foundation [type="checkbox"] ~ .fv-plugins-icon {
  top: -7px;
  /* labelHeight/2 - iconHeight/2 */ }

.fv-plugins-foundation.fv-stacked-form .fv-plugins-message-container {
  width: 100%; }

.fv-plugins-foundation.fv-stacked-form label .fv-plugins-icon,
.fv-plugins-foundation.fv-stacked-form fieldset [type="checkbox"] ~ .fv-plugins-icon,
.fv-plugins-foundation.fv-stacked-form fieldset [type="radio"] ~ .fv-plugins-icon {
  top: 25px;
  /* Same as height of label */ }

.fv-plugins-foundation .form-error {
  display: block; }

.fv-plugins-foundation .fv-row__success .fv-plugins-icon {
  color: #3adb76;
  /* Same as .success */ }

.fv-plugins-foundation .fv-row__error label,
.fv-plugins-foundation .fv-row__error fieldset legend,
.fv-plugins-foundation .fv-row__error .fv-plugins-icon {
  color: #cc4b37;
  /* Same as .is-invalid-label and .form-error */ }

.fv-plugins-materialize .fv-plugins-icon {
  height: 42px;
  /* Same as height of input */
  line-height: 42px;
  width: 42px; }

.fv-plugins-materialize .fv-plugins-icon-check {
  top: -10px; }

.fv-plugins-materialize .fv-invalid-row .helper-text,
.fv-plugins-materialize .fv-invalid-row .fv-plugins-icon {
  color: #F44336; }

.fv-plugins-materialize .fv-valid-row .helper-text,
.fv-plugins-materialize .fv-valid-row .fv-plugins-icon {
  color: #4CAF50; }

.fv-plugins-milligram .fv-plugins-icon {
  height: 38px;
  /* Same as height of input */
  line-height: 38px;
  width: 38px; }

.fv-plugins-milligram .column {
  position: relative; }

.fv-plugins-milligram .column .fv-plugins-icon {
  right: 10px; }

.fv-plugins-milligram .fv-plugins-icon-check {
  top: -6px; }

.fv-plugins-milligram .fv-plugins-message-container {
  margin-bottom: 15px; }

.fv-plugins-milligram.fv-stacked-form .fv-plugins-icon {
  top: 30px; }

.fv-plugins-milligram.fv-stacked-form .fv-plugins-icon-check {
  top: 24px; }

.fv-plugins-milligram .fv-invalid-row .fv-help-block,
.fv-plugins-milligram .fv-invalid-row .fv-plugins-icon {
  color: red; }

.fv-plugins-milligram .fv-valid-row .fv-help-block,
.fv-plugins-milligram .fv-valid-row .fv-plugins-icon {
  color: green; }

.fv-plugins-mini .fv-plugins-icon {
  height: 42px;
  /* Same as height of input */
  line-height: 42px;
  width: 42px;
  top: 4px;
  /* Same as input's margin top */ }

.fv-plugins-mini .fv-plugins-icon-check {
  top: -8px; }

.fv-plugins-mini.fv-stacked-form .fv-plugins-icon {
  top: 28px; }

.fv-plugins-mini.fv-stacked-form .fv-plugins-icon-check {
  top: 20px; }

.fv-plugins-mini .fv-plugins-message-container {
  margin: calc(var(--universal-margin) / 2); }

.fv-plugins-mini .fv-invalid-row .fv-help-block,
.fv-plugins-mini .fv-invalid-row .fv-plugins-icon {
  color: var(--input-invalid-color); }

.fv-plugins-mini .fv-valid-row .fv-help-block,
.fv-plugins-mini .fv-valid-row .fv-plugins-icon {
  color: #308732;
  /* Same as tertiary color */ }

.fv-plugins-mui .fv-plugins-icon {
  height: 32px;
  /* Same as height of input */
  line-height: 32px;
  width: 32px;
  top: 15px;
  right: 4px; }

.fv-plugins-mui .fv-plugins-icon-check {
  top: -6px;
  right: -10px; }

.fv-plugins-mui .fv-plugins-message-container {
  margin: 8px 0; }

.fv-plugins-mui .fv-invalid-row .fv-help-block,
.fv-plugins-mui .fv-invalid-row .fv-plugins-icon {
  color: #F44336; }

.fv-plugins-mui .fv-valid-row .fv-help-block,
.fv-plugins-mui .fv-valid-row .fv-plugins-icon {
  color: #4CAF50; }

.fv-plugins-pure {
  /* Horizontal form */
  /* Stacked form */ }

.fv-plugins-pure .fv-plugins-icon {
  height: 36px;
  line-height: 36px;
  width: 36px;
  /* Height of Pure input */ }

.fv-plugins-pure .fv-has-error label,
.fv-plugins-pure .fv-has-error .fv-help-block,
.fv-plugins-pure .fv-has-error .fv-plugins-icon {
  color: #CA3C3C;
  /* Same as .button-error */ }

.fv-plugins-pure .fv-has-success label,
.fv-plugins-pure .fv-has-success .fv-help-block,
.fv-plugins-pure .fv-has-success .fv-plugins-icon {
  color: #1CB841;
  /* Same as .button-success */ }

.fv-plugins-pure.pure-form-aligned .fv-help-block {
  margin-top: 5px;
  margin-left: 180px; }

.fv-plugins-pure.pure-form-aligned .fv-plugins-icon-check {
  top: -9px;
  /* labelHeight/2 - iconHeight/2 */ }

.fv-plugins-pure.pure-form-stacked .pure-control-group {
  margin-bottom: 8px; }

.fv-plugins-pure.pure-form-stacked .fv-plugins-icon {
  top: 22px;
  /* Same as height of label */ }

.fv-plugins-pure.pure-form-stacked .fv-plugins-icon-check {
  top: 13px; }

.fv-plugins-pure.pure-form-stacked .fv-sr-only ~ .fv-plugins-icon {
  top: -9px; }

.fv-plugins-semantic.ui.form .fields.error label,
.fv-plugins-semantic .error .fv-plugins-icon {
  color: #9f3a38;
  /* Same as .ui.form .field.error .input */ }

.fv-plugins-semantic .fv-plugins-icon-check {
  right: 7px; }

.fv-plugins-shoelace .input-group {
  margin-bottom: 0; }

.fv-plugins-shoelace .fv-plugins-icon {
  height: 32px;
  line-height: 32px;
  /* Same as height of input */
  width: 32px;
  top: 28px;
  /* Same as height of label */ }

.fv-plugins-shoelace .row .fv-plugins-icon {
  right: 16px;
  top: 0; }

.fv-plugins-shoelace .fv-plugins-icon-check {
  top: 24px; }

.fv-plugins-shoelace .fv-sr-only ~ .fv-plugins-icon,
.fv-plugins-shoelace .fv-sr-only ~ div .fv-plugins-icon {
  top: -4px; }

.fv-plugins-shoelace .input-valid .fv-help-block,
.fv-plugins-shoelace .input-valid .fv-plugins-icon {
  color: #2ecc40; }

.fv-plugins-shoelace .input-invalid .fv-help-block,
.fv-plugins-shoelace .input-invalid .fv-plugins-icon {
  color: #ff4136; }

.fv-plugins-spectre .form-checkbox .form-icon,
.fv-plugins-spectre .form-radio .form-icon {
  margin: 0; }

.fv-plugins-spectre .input-group .fv-plugins-icon {
  z-index: 2; }

.fv-plugins-spectre:not(.form-horizontal) .form-group.has-error .fv-plugins-icon {
  margin-top: 4px; }

.fv-plugins-spectre:not(.form-horizontal) .form-group.has-error .fv-plugins-icon-check {
  margin-top: 0; }

.fv-plugins-spectre:not(.form-horizontal) .form-group.has-success .fv-plugins-icon {
  margin-top: 15px; }

.fv-plugins-spectre:not(.form-horizontal) .form-group.has-success .fv-plugins-icon-check {
  margin-top: 0; }

.fv-plugins-tachyons .fv-plugins-icon {
  height: 36px;
  line-height: 36px;
  width: 36px; }

.fv-plugins-tachyons .fv-plugins-icon-check {
  top: -7px; }

.fv-plugins-tachyons.fv-stacked-form .fv-plugins-icon {
  top: 34px; }

.fv-plugins-tachyons.fv-stacked-form .fv-plugins-icon-check {
  top: 24px; }

.fv-plugins-turret .fv-plugins-icon {
  height: 48px;
  /* Same as height of input */
  line-height: 48px;
  width: 48px; }

.fv-plugins-turret.fv-stacked-form .fv-plugins-icon {
  top: 37px; }

.fv-plugins-turret.fv-stacked-form .fv-plugins-icon-check {
  top: 24px; }

.fv-plugins-turret .fv-invalid-row .form-message,
.fv-plugins-turret .fv-invalid-row .fv-plugins-icon {
  color: #c00;
  /* Same as .form-message.error */ }

.fv-plugins-turret .fv-valid-row .form-message,
.fv-plugins-turret .fv-valid-row .fv-plugins-icon {
  color: #00b300;
  /* Same as .form-message.success */ }

.fv-plugins-uikit {
  /* Horizontal form */
  /* Stacked form */ }

.fv-plugins-uikit .fv-plugins-icon {
  height: 40px;
  /* Height of UIKit input */
  line-height: 40px;
  top: 25px;
  /* Height of UIKit label */
  width: 40px; }

.fv-plugins-uikit.uk-form-horizontal .fv-plugins-icon {
  top: 0; }

.fv-plugins-uikit.uk-form-horizontal .fv-plugins-icon-check {
  top: -11px;
  /* checkboxLabelHeight/2 - iconHeight/2 = 18/2 - 40/2 */ }

.fv-plugins-uikit.uk-form-stacked .fv-plugins-icon-check {
  top: 15px;
  /* labelHeight + labelMarginBottom + checkboxLabelHeight/2 - iconHeight/2 = 21 + 5 + 18/2 - 40/2 */ }

.fv-plugins-uikit.uk-form-stacked .fv-no-label .fv-plugins-icon {
  top: 0; }

.fv-plugins-uikit.uk-form-stacked .fv-no-label .fv-plugins-icon-check {
  top: -11px; }

.fv-plugins-wizard--step {
  display: none; }

.fv-plugins-wizard--active {
  display: block; }

@font-face {
  font-family: 'unique-care-instructions';
  src: url(/static/media/unique-care-instructions.1565fe53.eot);
  src: url(/static/media/unique-care-instructions.1565fe53.eot#iefix) format("embedded-opentype"), url(/static/media/unique-care-instructions.26a96780.ttf) format("truetype"), url(/static/media/unique-care-instructions.2cc8fad7.woff) format("woff"), url(/static/media/unique-care-instructions.396730c7.svg#unique-care-instructions) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="uci-"], [class*=" uci-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'unique-care-instructions' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.uci-bleach-01:before {
  content: "\e903"; }

.uci-bleach-02:before {
  content: "\e904"; }

.uci-bleach-03:before {
  content: "\e905"; }

.uci-bleach-04:before {
  content: "\e901"; }

.uci-dry-01:before {
  content: "\e906"; }

.uci-dry-02:before {
  content: "\e907"; }

.uci-dry-03:before {
  content: "\e908"; }

.uci-dry-04:before {
  content: "\e909"; }

.uci-dry-05:before {
  content: "\e90a"; }

.uci-dry-06:before {
  content: "\e90b"; }

.uci-dry-07:before {
  content: "\e90c"; }

.uci-dry-08:before {
  content: "\e90d"; }

.uci-dry-09:before {
  content: "\e90e"; }

.uci-dry-10:before {
  content: "\e90f"; }

.uci-dry-11:before {
  content: "\e910"; }

.uci-dry-12:before {
  content: "\e911"; }

.uci-dry-13:before {
  content: "\e912"; }

.uci-dry-14:before {
  content: "\e913"; }

.uci-dry-15:before {
  content: "\e914"; }

.uci-dry-16:before {
  content: "\e915"; }

.uci-dry-17:before {
  content: "\e916"; }

.uci-dry-18:before {
  content: "\e917"; }

.uci-dryclean-01:before {
  content: "\e918"; }

.uci-dryclean-02:before {
  content: "\e919"; }

.uci-dryclean-03:before {
  content: "\e91a"; }

.uci-dryclean-04:before {
  content: "\e91b"; }

.uci-dryclean-05:before {
  content: "\e91c"; }

.uci-dryclean-06:before {
  content: "\e93c"; }

.uci-iron-01:before {
  content: "\e91d"; }

.uci-iron-02:before {
  content: "\e91e"; }

.uci-iron-03:before {
  content: "\e91f"; }

.uci-iron-04:before {
  content: "\e920"; }

.uci-iron-05:before {
  content: "\e921"; }

.uci-wash-01:before {
  content: "\e922"; }

.uci-wash-02:before {
  content: "\e923"; }

.uci-wash-03:before {
  content: "\e924"; }

.uci-wash-04:before {
  content: "\e925"; }

.uci-wash-05:before {
  content: "\e926"; }

.uci-wash-06:before {
  content: "\e927"; }

.uci-wash-07:before {
  content: "\e928"; }

.uci-wash-08:before {
  content: "\e929"; }

.uci-wash-09:before {
  content: "\e92a"; }

.uci-wash-10:before {
  content: "\e92b"; }

.uci-wash-11:before {
  content: "\e92c"; }

.uci-wash-12:before {
  content: "\e92d"; }

.uci-wash-13:before {
  content: "\e92e"; }

.uci-wash-14:before {
  content: "\e92f"; }

.uci-wash-15:before {
  content: "\e930"; }

.uci-wash-16:before {
  content: "\e931"; }

.uci-wash-17:before {
  content: "\e932"; }

.uci-wash-18:before {
  content: "\e933"; }

.uci-wash-19:before {
  content: "\e934"; }

.uci-wash-20:before {
  content: "\e935"; }

.uci-wash-21:before {
  content: "\e93b"; }

.uci-wash-22:before {
  content: "\e93a"; }

.uci-wash-23:before {
  content: "\e939"; }

.uci-wash-24:before {
  content: "\e938"; }

.uci-wash-25:before {
  content: "\e937"; }

.uci-wash-26:before {
  content: "\e936"; }

.uci-wash-50:before {
  content: "\e900"; }

.uci-wash-51:before {
  content: "\e902"; }

/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #00aeef;
  --secondary: #f4f4f4;
  --success: #091426;
  --info: #6FA4CA;
  --warning: #F5A623;
  --danger: #DC3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --placed: #AB3511;
  --art-prepared: #E27938;
  --art-approved: #E1D35F;
  --production: #0F6C91;
  --finance: #918A07;
  --shipped: #8A12BF;
  --unique-blue: #009FDF;
  --unique-blue-highlight: #00b6ff;
  --unique-text: #4d4d4f;
  --unique-grey: #7C828A;
  --unique-grey-light: #b1b1b1;
  --unique-red: #DC3545;
  --unique-red-highlight: #ba2d3a;
  --unique-green: #60af75;
  --unique-grey-lightUltra: #f1f1f1;
  --unique-silver: #999999;
  --unique-yellow: #dac302;
  --unique-input: #4d4d4f;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Montserrat", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4d4d4f;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #009FDF;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #006893;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #4d4d4f; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #4d4d4f;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8e8fb; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ad5f7; }

.table-hover .table-primary:hover {
  background-color: #a0e0fa; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a0e0fa; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fcfcfc; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f9f9f9; }

.table-hover .table-secondary:hover {
  background-color: #efefef; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #efefef; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #babdc2; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7f858e; }

.table-hover .table-success:hover {
  background-color: #acb0b6; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #acb0b6; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d7e6f0; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #b4d0e3; }

.table-hover .table-info:hover {
  background-color: #c4dae9; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #c4dae9; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fce6c1; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fad18d; }

.table-hover .table-warning:hover {
  background-color: #fbdca9; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fbdca9; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-placed,
.table-placed > th,
.table-placed > td {
  background-color: #e7c6bc; }

.table-placed th,
.table-placed td,
.table-placed thead th,
.table-placed tbody + tbody {
  border-color: #d39683; }

.table-hover .table-placed:hover {
  background-color: #e0b6a9; }
  .table-hover .table-placed:hover > td,
  .table-hover .table-placed:hover > th {
    background-color: #e0b6a9; }

.table-art-prepared,
.table-art-prepared > th,
.table-art-prepared > td {
  background-color: #f7d9c7; }

.table-art-prepared th,
.table-art-prepared td,
.table-art-prepared thead th,
.table-art-prepared tbody + tbody {
  border-color: #f0b998; }

.table-hover .table-art-prepared:hover {
  background-color: #f4cab1; }
  .table-hover .table-art-prepared:hover > td,
  .table-hover .table-art-prepared:hover > th {
    background-color: #f4cab1; }

.table-art-approved,
.table-art-approved > th,
.table-art-approved > td {
  background-color: #f7f3d2; }

.table-art-approved th,
.table-art-approved td,
.table-art-approved thead th,
.table-art-approved tbody + tbody {
  border-color: #efe8ac; }

.table-hover .table-art-approved:hover {
  background-color: #f3edbc; }
  .table-hover .table-art-approved:hover > td,
  .table-hover .table-art-approved:hover > th {
    background-color: #f3edbc; }

.table-production,
.table-production > th,
.table-production > td {
  background-color: #bcd6e0; }

.table-production th,
.table-production td,
.table-production thead th,
.table-production tbody + tbody {
  border-color: #82b3c6; }

.table-hover .table-production:hover {
  background-color: #abcbd8; }
  .table-hover .table-production:hover > td,
  .table-hover .table-production:hover > th {
    background-color: #abcbd8; }

.table-finance,
.table-finance > th,
.table-finance > td {
  background-color: #e0deba; }

.table-finance th,
.table-finance td,
.table-finance thead th,
.table-finance tbody + tbody {
  border-color: #c6c27e; }

.table-hover .table-finance:hover {
  background-color: #d8d6a8; }
  .table-hover .table-finance:hover > td,
  .table-hover .table-finance:hover > th {
    background-color: #d8d6a8; }

.table-shipped,
.table-shipped > th,
.table-shipped > td {
  background-color: #debded; }

.table-shipped th,
.table-shipped td,
.table-shipped thead th,
.table-shipped tbody + tbody {
  border-color: #c284de; }

.table-hover .table-shipped:hover {
  background-color: #d4a9e8; }
  .table-hover .table-shipped:hover > td,
  .table-hover .table-shipped:hover > th {
    background-color: #d4a9e8; }

.table-unique-blue,
.table-unique-blue > th,
.table-unique-blue > td {
  background-color: #b8e4f6; }

.table-unique-blue th,
.table-unique-blue td,
.table-unique-blue thead th,
.table-unique-blue tbody + tbody {
  border-color: #7acdee; }

.table-hover .table-unique-blue:hover {
  background-color: #a1dbf3; }
  .table-hover .table-unique-blue:hover > td,
  .table-hover .table-unique-blue:hover > th {
    background-color: #a1dbf3; }

.table-unique-blue-highlight,
.table-unique-blue-highlight > th,
.table-unique-blue-highlight > td {
  background-color: #b8ebff; }

.table-unique-blue-highlight th,
.table-unique-blue-highlight td,
.table-unique-blue-highlight thead th,
.table-unique-blue-highlight tbody + tbody {
  border-color: #7ad9ff; }

.table-hover .table-unique-blue-highlight:hover {
  background-color: #9fe4ff; }
  .table-hover .table-unique-blue-highlight:hover > td,
  .table-hover .table-unique-blue-highlight:hover > th {
    background-color: #9fe4ff; }

.table-unique-text,
.table-unique-text > th,
.table-unique-text > td {
  background-color: #cdcdce; }

.table-unique-text th,
.table-unique-text td,
.table-unique-text thead th,
.table-unique-text tbody + tbody {
  border-color: #a2a2a3; }

.table-hover .table-unique-text:hover {
  background-color: #c0c0c1; }
  .table-hover .table-unique-text:hover > td,
  .table-hover .table-unique-text:hover > th {
    background-color: #c0c0c1; }

.table-unique-grey,
.table-unique-grey > th,
.table-unique-grey > td {
  background-color: #dadcde; }

.table-unique-grey th,
.table-unique-grey td,
.table-unique-grey thead th,
.table-unique-grey tbody + tbody {
  border-color: #bbbec2; }

.table-hover .table-unique-grey:hover {
  background-color: #cdcfd2; }
  .table-hover .table-unique-grey:hover > td,
  .table-hover .table-unique-grey:hover > th {
    background-color: #cdcfd2; }

.table-unique-grey-light,
.table-unique-grey-light > th,
.table-unique-grey-light > td {
  background-color: #e9e9e9; }

.table-unique-grey-light th,
.table-unique-grey-light td,
.table-unique-grey-light thead th,
.table-unique-grey-light tbody + tbody {
  border-color: #d6d6d6; }

.table-hover .table-unique-grey-light:hover {
  background-color: gainsboro; }
  .table-hover .table-unique-grey-light:hover > td,
  .table-hover .table-unique-grey-light:hover > th {
    background-color: gainsboro; }

.table-unique-red,
.table-unique-red > th,
.table-unique-red > td {
  background-color: #f5c6cb; }

.table-unique-red th,
.table-unique-red td,
.table-unique-red thead th,
.table-unique-red tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-unique-red:hover {
  background-color: #f1b0b7; }
  .table-hover .table-unique-red:hover > td,
  .table-hover .table-unique-red:hover > th {
    background-color: #f1b0b7; }

.table-unique-red-highlight,
.table-unique-red-highlight > th,
.table-unique-red-highlight > td {
  background-color: #ecc4c8; }

.table-unique-red-highlight th,
.table-unique-red-highlight td,
.table-unique-red-highlight thead th,
.table-unique-red-highlight tbody + tbody {
  border-color: #db9299; }

.table-hover .table-unique-red-highlight:hover {
  background-color: #e6b1b6; }
  .table-hover .table-unique-red-highlight:hover > td,
  .table-hover .table-unique-red-highlight:hover > th {
    background-color: #e6b1b6; }

.table-unique-green,
.table-unique-green > th,
.table-unique-green > td {
  background-color: #d2e9d8; }

.table-unique-green th,
.table-unique-green td,
.table-unique-green thead th,
.table-unique-green tbody + tbody {
  border-color: #acd5b7; }

.table-hover .table-unique-green:hover {
  background-color: #c1e1c9; }
  .table-hover .table-unique-green:hover > td,
  .table-hover .table-unique-green:hover > th {
    background-color: #c1e1c9; }

.table-unique-grey-lightUltra,
.table-unique-grey-lightUltra > th,
.table-unique-grey-lightUltra > td {
  background-color: #fbfbfb; }

.table-unique-grey-lightUltra th,
.table-unique-grey-lightUltra td,
.table-unique-grey-lightUltra thead th,
.table-unique-grey-lightUltra tbody + tbody {
  border-color: #f8f8f8; }

.table-hover .table-unique-grey-lightUltra:hover {
  background-color: #eeeeee; }
  .table-hover .table-unique-grey-lightUltra:hover > td,
  .table-hover .table-unique-grey-lightUltra:hover > th {
    background-color: #eeeeee; }

.table-unique-silver,
.table-unique-silver > th,
.table-unique-silver > td {
  background-color: #e2e2e2; }

.table-unique-silver th,
.table-unique-silver td,
.table-unique-silver thead th,
.table-unique-silver tbody + tbody {
  border-color: #cacaca; }

.table-hover .table-unique-silver:hover {
  background-color: #d5d5d5; }
  .table-hover .table-unique-silver:hover > td,
  .table-hover .table-unique-silver:hover > th {
    background-color: #d5d5d5; }

.table-unique-yellow,
.table-unique-yellow > th,
.table-unique-yellow > td {
  background-color: #f5eeb8; }

.table-unique-yellow th,
.table-unique-yellow td,
.table-unique-yellow thead th,
.table-unique-yellow tbody + tbody {
  border-color: #ece07b; }

.table-hover .table-unique-yellow:hover {
  background-color: #f2e9a2; }
  .table-hover .table-unique-yellow:hover > td,
  .table-hover .table-unique-yellow:hover > th {
    background-color: #f2e9a2; }

.table-unique-input,
.table-unique-input > th,
.table-unique-input > td {
  background-color: #cdcdce; }

.table-unique-input th,
.table-unique-input td,
.table-unique-input thead th,
.table-unique-input tbody + tbody {
  border-color: #a2a2a3; }

.table-hover .table-unique-input:hover {
  background-color: #c0c0c1; }
  .table-hover .table-unique-input:hover > td,
  .table-hover .table-unique-input:hover > th {
    background-color: #c0c0c1; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #a5bae2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 112, 195, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  font-size: 1.125rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #4d4d4f;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  resize: none; }
  textarea.form-control.address-field {
    margin-top: 1em;
    height: 8.8em; }
  textarea.form-control.comments-field {
    height: 8em; }

.react-datepicker-wrapper {
  flex: 1 1; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #091426; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(9, 20, 38, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #091426;
  padding-right: calc(1.5em + 0.75rem);
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #091426;
    box-shadow: 0 0 0 0.2rem rgba(9, 20, 38, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #091426;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, none #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #091426;
    box-shadow: 0 0 0 0.2rem rgba(9, 20, 38, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #091426; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #091426; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #091426; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #132a4f;
  background-color: #132a4f; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(9, 20, 38, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #091426; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #091426; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #091426;
  box-shadow: 0 0 0 0.2rem rgba(9, 20, 38, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #DC3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #DC3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23DC3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DC3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #DC3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #DC3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23DC3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DC3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #DC3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #DC3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #DC3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #DC3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #DC3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #DC3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #DC3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #4d4d4f;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 2rem;
  font-size: 0.875rem;
  line-height: 1.714;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #4d4d4f;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 112, 195, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #00aeef;
  border-color: #00aeef; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0092c9;
    border-color: #0089bc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0092c9;
    border-color: #0089bc;
    box-shadow: 0 0 0 0.2rem rgba(38, 186, 241, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #00aeef;
    border-color: #00aeef; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0089bc;
    border-color: #0080af; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 186, 241, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #e1e1e1;
    border-color: #dbdbdb; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #e1e1e1;
    border-color: #dbdbdb;
    box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #f4f4f4;
    border-color: #f4f4f4; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #dbdbdb;
    border-color: #d4d4d4; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5); }

.btn-success {
  color: #fff;
  background-color: #091426;
  border-color: #091426; }
  .btn-success:hover {
    color: #fff;
    background-color: #020407;
    border-color: black; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #020407;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(46, 55, 71, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #091426;
    border-color: #091426; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(46, 55, 71, 0.5); }

.btn-info {
  color: #212529;
  background-color: #6FA4CA;
  border-color: #6FA4CA; }
  .btn-info:hover {
    color: #fff;
    background-color: #5392c0;
    border-color: #4a8cbc; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #5392c0;
    border-color: #4a8cbc;
    box-shadow: 0 0 0 0.2rem rgba(99, 145, 178, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #212529;
    background-color: #6FA4CA;
    border-color: #6FA4CA; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #4a8cbc;
    border-color: #4386b6; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(99, 145, 178, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #F5A623;
  border-color: #F5A623; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e7940b;
    border-color: #db8c0a; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e7940b;
    border-color: #db8c0a;
    box-shadow: 0 0 0 0.2rem rgba(213, 147, 36, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #F5A623;
    border-color: #F5A623; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #db8c0a;
    border-color: #cf8509; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(213, 147, 36, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #DC3545;
  border-color: #DC3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-placed {
  color: #fff;
  background-color: #AB3511;
  border-color: #AB3511; }
  .btn-placed:hover {
    color: #fff;
    background-color: #882a0e;
    border-color: #7d270c; }
  .btn-placed:focus, .btn-placed.focus {
    color: #fff;
    background-color: #882a0e;
    border-color: #7d270c;
    box-shadow: 0 0 0 0.2rem rgba(184, 83, 53, 0.5); }
  .btn-placed.disabled, .btn-placed:disabled {
    color: #fff;
    background-color: #AB3511;
    border-color: #AB3511; }
  .btn-placed:not(:disabled):not(.disabled):active, .btn-placed:not(:disabled):not(.disabled).active,
  .show > .btn-placed.dropdown-toggle {
    color: #fff;
    background-color: #7d270c;
    border-color: #71230b; }
    .btn-placed:not(:disabled):not(.disabled):active:focus, .btn-placed:not(:disabled):not(.disabled).active:focus,
    .show > .btn-placed.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(184, 83, 53, 0.5); }

.btn-art-prepared {
  color: #fff;
  background-color: #E27938;
  border-color: #E27938; }
  .btn-art-prepared:hover {
    color: #fff;
    background-color: #d5641f;
    border-color: #ca5f1d; }
  .btn-art-prepared:focus, .btn-art-prepared.focus {
    color: #fff;
    background-color: #d5641f;
    border-color: #ca5f1d;
    box-shadow: 0 0 0 0.2rem rgba(230, 141, 86, 0.5); }
  .btn-art-prepared.disabled, .btn-art-prepared:disabled {
    color: #fff;
    background-color: #E27938;
    border-color: #E27938; }
  .btn-art-prepared:not(:disabled):not(.disabled):active, .btn-art-prepared:not(:disabled):not(.disabled).active,
  .show > .btn-art-prepared.dropdown-toggle {
    color: #fff;
    background-color: #ca5f1d;
    border-color: #be5a1c; }
    .btn-art-prepared:not(:disabled):not(.disabled):active:focus, .btn-art-prepared:not(:disabled):not(.disabled).active:focus,
    .show > .btn-art-prepared.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(230, 141, 86, 0.5); }

.btn-art-approved {
  color: #212529;
  background-color: #E1D35F;
  border-color: #E1D35F; }
  .btn-art-approved:hover {
    color: #212529;
    background-color: #dbca3f;
    border-color: #d9c734; }
  .btn-art-approved:focus, .btn-art-approved.focus {
    color: #212529;
    background-color: #dbca3f;
    border-color: #d9c734;
    box-shadow: 0 0 0 0.2rem rgba(196, 185, 87, 0.5); }
  .btn-art-approved.disabled, .btn-art-approved:disabled {
    color: #212529;
    background-color: #E1D35F;
    border-color: #E1D35F; }
  .btn-art-approved:not(:disabled):not(.disabled):active, .btn-art-approved:not(:disabled):not(.disabled).active,
  .show > .btn-art-approved.dropdown-toggle {
    color: #212529;
    background-color: #d9c734;
    border-color: #d7c429; }
    .btn-art-approved:not(:disabled):not(.disabled):active:focus, .btn-art-approved:not(:disabled):not(.disabled).active:focus,
    .show > .btn-art-approved.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 185, 87, 0.5); }

.btn-production {
  color: #fff;
  background-color: #0F6C91;
  border-color: #0F6C91; }
  .btn-production:hover {
    color: #fff;
    background-color: #0b526e;
    border-color: #0a4a63; }
  .btn-production:focus, .btn-production.focus {
    color: #fff;
    background-color: #0b526e;
    border-color: #0a4a63;
    box-shadow: 0 0 0 0.2rem rgba(51, 130, 162, 0.5); }
  .btn-production.disabled, .btn-production:disabled {
    color: #fff;
    background-color: #0F6C91;
    border-color: #0F6C91; }
  .btn-production:not(:disabled):not(.disabled):active, .btn-production:not(:disabled):not(.disabled).active,
  .show > .btn-production.dropdown-toggle {
    color: #fff;
    background-color: #0a4a63;
    border-color: #094157; }
    .btn-production:not(:disabled):not(.disabled):active:focus, .btn-production:not(:disabled):not(.disabled).active:focus,
    .show > .btn-production.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(51, 130, 162, 0.5); }

.btn-finance {
  color: #fff;
  background-color: #918A07;
  border-color: #918A07; }
  .btn-finance:hover {
    color: #fff;
    background-color: #6d6705;
    border-color: #605c05; }
  .btn-finance:focus, .btn-finance.focus {
    color: #fff;
    background-color: #6d6705;
    border-color: #605c05;
    box-shadow: 0 0 0 0.2rem rgba(162, 156, 44, 0.5); }
  .btn-finance.disabled, .btn-finance:disabled {
    color: #fff;
    background-color: #918A07;
    border-color: #918A07; }
  .btn-finance:not(:disabled):not(.disabled):active, .btn-finance:not(:disabled):not(.disabled).active,
  .show > .btn-finance.dropdown-toggle {
    color: #fff;
    background-color: #605c05;
    border-color: #545004; }
    .btn-finance:not(:disabled):not(.disabled):active:focus, .btn-finance:not(:disabled):not(.disabled).active:focus,
    .show > .btn-finance.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(162, 156, 44, 0.5); }

.btn-shipped {
  color: #fff;
  background-color: #8A12BF;
  border-color: #8A12BF; }
  .btn-shipped:hover {
    color: #fff;
    background-color: #710f9c;
    border-color: #680e90; }
  .btn-shipped:focus, .btn-shipped.focus {
    color: #fff;
    background-color: #710f9c;
    border-color: #680e90;
    box-shadow: 0 0 0 0.2rem rgba(156, 54, 201, 0.5); }
  .btn-shipped.disabled, .btn-shipped:disabled {
    color: #fff;
    background-color: #8A12BF;
    border-color: #8A12BF; }
  .btn-shipped:not(:disabled):not(.disabled):active, .btn-shipped:not(:disabled):not(.disabled).active,
  .show > .btn-shipped.dropdown-toggle {
    color: #fff;
    background-color: #680e90;
    border-color: #600d85; }
    .btn-shipped:not(:disabled):not(.disabled):active:focus, .btn-shipped:not(:disabled):not(.disabled).active:focus,
    .show > .btn-shipped.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(156, 54, 201, 0.5); }

.btn-unique-blue {
  color: #fff;
  background-color: #009FDF;
  border-color: #009FDF; }
  .btn-unique-blue:hover {
    color: #fff;
    background-color: #0084b9;
    border-color: #007bac; }
  .btn-unique-blue:focus, .btn-unique-blue.focus {
    color: #fff;
    background-color: #0084b9;
    border-color: #007bac;
    box-shadow: 0 0 0 0.2rem rgba(38, 173, 228, 0.5); }
  .btn-unique-blue.disabled, .btn-unique-blue:disabled {
    color: #fff;
    background-color: #009FDF;
    border-color: #009FDF; }
  .btn-unique-blue:not(:disabled):not(.disabled):active, .btn-unique-blue:not(:disabled):not(.disabled).active,
  .show > .btn-unique-blue.dropdown-toggle {
    color: #fff;
    background-color: #007bac;
    border-color: #00729f; }
    .btn-unique-blue:not(:disabled):not(.disabled):active:focus, .btn-unique-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-unique-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 173, 228, 0.5); }

.btn-unique-blue-highlight {
  color: #fff;
  background-color: #00b6ff;
  border-color: #00b6ff; }
  .btn-unique-blue-highlight:hover {
    color: #fff;
    background-color: #009bd9;
    border-color: #0092cc; }
  .btn-unique-blue-highlight:focus, .btn-unique-blue-highlight.focus {
    color: #fff;
    background-color: #009bd9;
    border-color: #0092cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 193, 255, 0.5); }
  .btn-unique-blue-highlight.disabled, .btn-unique-blue-highlight:disabled {
    color: #fff;
    background-color: #00b6ff;
    border-color: #00b6ff; }
  .btn-unique-blue-highlight:not(:disabled):not(.disabled):active, .btn-unique-blue-highlight:not(:disabled):not(.disabled).active,
  .show > .btn-unique-blue-highlight.dropdown-toggle {
    color: #fff;
    background-color: #0092cc;
    border-color: #0089bf; }
    .btn-unique-blue-highlight:not(:disabled):not(.disabled):active:focus, .btn-unique-blue-highlight:not(:disabled):not(.disabled).active:focus,
    .show > .btn-unique-blue-highlight.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 193, 255, 0.5); }

.btn-unique-text {
  color: #fff;
  background-color: #4d4d4f;
  border-color: #4d4d4f; }
  .btn-unique-text:hover {
    color: #fff;
    background-color: #3a3a3c;
    border-color: #343435; }
  .btn-unique-text:focus, .btn-unique-text.focus {
    color: #fff;
    background-color: #3a3a3c;
    border-color: #343435;
    box-shadow: 0 0 0 0.2rem rgba(104, 104, 105, 0.5); }
  .btn-unique-text.disabled, .btn-unique-text:disabled {
    color: #fff;
    background-color: #4d4d4f;
    border-color: #4d4d4f; }
  .btn-unique-text:not(:disabled):not(.disabled):active, .btn-unique-text:not(:disabled):not(.disabled).active,
  .show > .btn-unique-text.dropdown-toggle {
    color: #fff;
    background-color: #343435;
    border-color: #2e2e2f; }
    .btn-unique-text:not(:disabled):not(.disabled):active:focus, .btn-unique-text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-unique-text.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(104, 104, 105, 0.5); }

.btn-unique-grey {
  color: #fff;
  background-color: #7C828A;
  border-color: #7C828A; }
  .btn-unique-grey:hover {
    color: #fff;
    background-color: #6a6f76;
    border-color: #64696f; }
  .btn-unique-grey:focus, .btn-unique-grey.focus {
    color: #fff;
    background-color: #6a6f76;
    border-color: #64696f;
    box-shadow: 0 0 0 0.2rem rgba(144, 149, 156, 0.5); }
  .btn-unique-grey.disabled, .btn-unique-grey:disabled {
    color: #fff;
    background-color: #7C828A;
    border-color: #7C828A; }
  .btn-unique-grey:not(:disabled):not(.disabled):active, .btn-unique-grey:not(:disabled):not(.disabled).active,
  .show > .btn-unique-grey.dropdown-toggle {
    color: #fff;
    background-color: #64696f;
    border-color: #5e6269; }
    .btn-unique-grey:not(:disabled):not(.disabled):active:focus, .btn-unique-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-unique-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(144, 149, 156, 0.5); }

.btn-unique-grey-light {
  color: #212529;
  background-color: #b1b1b1;
  border-color: #b1b1b1; }
  .btn-unique-grey-light:hover {
    color: #212529;
    background-color: #9e9e9e;
    border-color: #989898; }
  .btn-unique-grey-light:focus, .btn-unique-grey-light.focus {
    color: #212529;
    background-color: #9e9e9e;
    border-color: #989898;
    box-shadow: 0 0 0 0.2rem rgba(155, 156, 157, 0.5); }
  .btn-unique-grey-light.disabled, .btn-unique-grey-light:disabled {
    color: #212529;
    background-color: #b1b1b1;
    border-color: #b1b1b1; }
  .btn-unique-grey-light:not(:disabled):not(.disabled):active, .btn-unique-grey-light:not(:disabled):not(.disabled).active,
  .show > .btn-unique-grey-light.dropdown-toggle {
    color: #212529;
    background-color: #989898;
    border-color: #919191; }
    .btn-unique-grey-light:not(:disabled):not(.disabled):active:focus, .btn-unique-grey-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-unique-grey-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(155, 156, 157, 0.5); }

.btn-unique-red {
  color: #fff;
  background-color: #DC3545;
  border-color: #DC3545; }
  .btn-unique-red:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-unique-red:focus, .btn-unique-red.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-unique-red.disabled, .btn-unique-red:disabled {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545; }
  .btn-unique-red:not(:disabled):not(.disabled):active, .btn-unique-red:not(:disabled):not(.disabled).active,
  .show > .btn-unique-red.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-unique-red:not(:disabled):not(.disabled):active:focus, .btn-unique-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-unique-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-unique-red-highlight {
  color: #fff;
  background-color: #ba2d3a;
  border-color: #ba2d3a; }
  .btn-unique-red-highlight:hover {
    color: #fff;
    background-color: #9b2630;
    border-color: #91232d; }
  .btn-unique-red-highlight:focus, .btn-unique-red-highlight.focus {
    color: #fff;
    background-color: #9b2630;
    border-color: #91232d;
    box-shadow: 0 0 0 0.2rem rgba(196, 77, 88, 0.5); }
  .btn-unique-red-highlight.disabled, .btn-unique-red-highlight:disabled {
    color: #fff;
    background-color: #ba2d3a;
    border-color: #ba2d3a; }
  .btn-unique-red-highlight:not(:disabled):not(.disabled):active, .btn-unique-red-highlight:not(:disabled):not(.disabled).active,
  .show > .btn-unique-red-highlight.dropdown-toggle {
    color: #fff;
    background-color: #91232d;
    border-color: #87212a; }
    .btn-unique-red-highlight:not(:disabled):not(.disabled):active:focus, .btn-unique-red-highlight:not(:disabled):not(.disabled).active:focus,
    .show > .btn-unique-red-highlight.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 77, 88, 0.5); }

.btn-unique-green {
  color: #fff;
  background-color: #60af75;
  border-color: #60af75; }
  .btn-unique-green:hover {
    color: #fff;
    background-color: #4e9b62;
    border-color: #4a925d; }
  .btn-unique-green:focus, .btn-unique-green.focus {
    color: #fff;
    background-color: #4e9b62;
    border-color: #4a925d;
    box-shadow: 0 0 0 0.2rem rgba(120, 187, 138, 0.5); }
  .btn-unique-green.disabled, .btn-unique-green:disabled {
    color: #fff;
    background-color: #60af75;
    border-color: #60af75; }
  .btn-unique-green:not(:disabled):not(.disabled):active, .btn-unique-green:not(:disabled):not(.disabled).active,
  .show > .btn-unique-green.dropdown-toggle {
    color: #fff;
    background-color: #4a925d;
    border-color: #458a58; }
    .btn-unique-green:not(:disabled):not(.disabled):active:focus, .btn-unique-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-unique-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(120, 187, 138, 0.5); }

.btn-unique-grey-lightUltra {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1; }
  .btn-unique-grey-lightUltra:hover {
    color: #212529;
    background-color: #dedede;
    border-color: #d8d8d8; }
  .btn-unique-grey-lightUltra:focus, .btn-unique-grey-lightUltra.focus {
    color: #212529;
    background-color: #dedede;
    border-color: #d8d8d8;
    box-shadow: 0 0 0 0.2rem rgba(210, 210, 211, 0.5); }
  .btn-unique-grey-lightUltra.disabled, .btn-unique-grey-lightUltra:disabled {
    color: #212529;
    background-color: #f1f1f1;
    border-color: #f1f1f1; }
  .btn-unique-grey-lightUltra:not(:disabled):not(.disabled):active, .btn-unique-grey-lightUltra:not(:disabled):not(.disabled).active,
  .show > .btn-unique-grey-lightUltra.dropdown-toggle {
    color: #212529;
    background-color: #d8d8d8;
    border-color: #d1d1d1; }
    .btn-unique-grey-lightUltra:not(:disabled):not(.disabled):active:focus, .btn-unique-grey-lightUltra:not(:disabled):not(.disabled).active:focus,
    .show > .btn-unique-grey-lightUltra.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(210, 210, 211, 0.5); }

.btn-unique-silver {
  color: #212529;
  background-color: #999999;
  border-color: #999999; }
  .btn-unique-silver:hover {
    color: #fff;
    background-color: #868686;
    border-color: gray; }
  .btn-unique-silver:focus, .btn-unique-silver.focus {
    color: #fff;
    background-color: #868686;
    border-color: gray;
    box-shadow: 0 0 0 0.2rem rgba(135, 136, 136, 0.5); }
  .btn-unique-silver.disabled, .btn-unique-silver:disabled {
    color: #212529;
    background-color: #999999;
    border-color: #999999; }
  .btn-unique-silver:not(:disabled):not(.disabled):active, .btn-unique-silver:not(:disabled):not(.disabled).active,
  .show > .btn-unique-silver.dropdown-toggle {
    color: #fff;
    background-color: gray;
    border-color: #797979; }
    .btn-unique-silver:not(:disabled):not(.disabled):active:focus, .btn-unique-silver:not(:disabled):not(.disabled).active:focus,
    .show > .btn-unique-silver.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(135, 136, 136, 0.5); }

.btn-unique-yellow {
  color: #212529;
  background-color: #dac302;
  border-color: #dac302; }
  .btn-unique-yellow:hover {
    color: #fff;
    background-color: #b4a102;
    border-color: #a79602; }
  .btn-unique-yellow:focus, .btn-unique-yellow.focus {
    color: #fff;
    background-color: #b4a102;
    border-color: #a79602;
    box-shadow: 0 0 0 0.2rem rgba(190, 171, 8, 0.5); }
  .btn-unique-yellow.disabled, .btn-unique-yellow:disabled {
    color: #212529;
    background-color: #dac302;
    border-color: #dac302; }
  .btn-unique-yellow:not(:disabled):not(.disabled):active, .btn-unique-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-unique-yellow.dropdown-toggle {
    color: #fff;
    background-color: #a79602;
    border-color: #9b8a01; }
    .btn-unique-yellow:not(:disabled):not(.disabled):active:focus, .btn-unique-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-unique-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(190, 171, 8, 0.5); }

.btn-unique-input {
  color: #fff;
  background-color: #4d4d4f;
  border-color: #4d4d4f; }
  .btn-unique-input:hover {
    color: #fff;
    background-color: #3a3a3c;
    border-color: #343435; }
  .btn-unique-input:focus, .btn-unique-input.focus {
    color: #fff;
    background-color: #3a3a3c;
    border-color: #343435;
    box-shadow: 0 0 0 0.2rem rgba(104, 104, 105, 0.5); }
  .btn-unique-input.disabled, .btn-unique-input:disabled {
    color: #fff;
    background-color: #4d4d4f;
    border-color: #4d4d4f; }
  .btn-unique-input:not(:disabled):not(.disabled):active, .btn-unique-input:not(:disabled):not(.disabled).active,
  .show > .btn-unique-input.dropdown-toggle {
    color: #fff;
    background-color: #343435;
    border-color: #2e2e2f; }
    .btn-unique-input:not(:disabled):not(.disabled):active:focus, .btn-unique-input:not(:disabled):not(.disabled).active:focus,
    .show > .btn-unique-input.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(104, 104, 105, 0.5); }

.btn-outline-primary {
  color: #00aeef;
  border-color: #00aeef; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #00aeef;
    border-color: #00aeef; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 174, 239, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #00aeef;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #00aeef;
    border-color: #00aeef; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 174, 239, 0.5); }

.btn-outline-secondary {
  color: #f4f4f4;
  border-color: #f4f4f4; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #f4f4f4;
    border-color: #f4f4f4; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #f4f4f4;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #f4f4f4;
    border-color: #f4f4f4; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5); }

.btn-outline-success {
  color: #091426;
  border-color: #091426; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #091426;
    border-color: #091426; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(9, 20, 38, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #091426;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #091426;
    border-color: #091426; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(9, 20, 38, 0.5); }

.btn-outline-info {
  color: #6FA4CA;
  border-color: #6FA4CA; }
  .btn-outline-info:hover {
    color: #212529;
    background-color: #6FA4CA;
    border-color: #6FA4CA; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(111, 164, 202, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #6FA4CA;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #6FA4CA;
    border-color: #6FA4CA; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(111, 164, 202, 0.5); }

.btn-outline-warning {
  color: #F5A623;
  border-color: #F5A623; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #F5A623;
    border-color: #F5A623; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 166, 35, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #F5A623;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #F5A623;
    border-color: #F5A623; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 166, 35, 0.5); }

.btn-outline-danger {
  color: #DC3545;
  border-color: #DC3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #DC3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-placed {
  color: #AB3511;
  border-color: #AB3511; }
  .btn-outline-placed:hover {
    color: #fff;
    background-color: #AB3511;
    border-color: #AB3511; }
  .btn-outline-placed:focus, .btn-outline-placed.focus {
    box-shadow: 0 0 0 0.2rem rgba(171, 53, 17, 0.5); }
  .btn-outline-placed.disabled, .btn-outline-placed:disabled {
    color: #AB3511;
    background-color: transparent; }
  .btn-outline-placed:not(:disabled):not(.disabled):active, .btn-outline-placed:not(:disabled):not(.disabled).active,
  .show > .btn-outline-placed.dropdown-toggle {
    color: #fff;
    background-color: #AB3511;
    border-color: #AB3511; }
    .btn-outline-placed:not(:disabled):not(.disabled):active:focus, .btn-outline-placed:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-placed.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(171, 53, 17, 0.5); }

.btn-outline-art-prepared {
  color: #E27938;
  border-color: #E27938; }
  .btn-outline-art-prepared:hover {
    color: #fff;
    background-color: #E27938;
    border-color: #E27938; }
  .btn-outline-art-prepared:focus, .btn-outline-art-prepared.focus {
    box-shadow: 0 0 0 0.2rem rgba(226, 121, 56, 0.5); }
  .btn-outline-art-prepared.disabled, .btn-outline-art-prepared:disabled {
    color: #E27938;
    background-color: transparent; }
  .btn-outline-art-prepared:not(:disabled):not(.disabled):active, .btn-outline-art-prepared:not(:disabled):not(.disabled).active,
  .show > .btn-outline-art-prepared.dropdown-toggle {
    color: #fff;
    background-color: #E27938;
    border-color: #E27938; }
    .btn-outline-art-prepared:not(:disabled):not(.disabled):active:focus, .btn-outline-art-prepared:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-art-prepared.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(226, 121, 56, 0.5); }

.btn-outline-art-approved {
  color: #E1D35F;
  border-color: #E1D35F; }
  .btn-outline-art-approved:hover {
    color: #212529;
    background-color: #E1D35F;
    border-color: #E1D35F; }
  .btn-outline-art-approved:focus, .btn-outline-art-approved.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 211, 95, 0.5); }
  .btn-outline-art-approved.disabled, .btn-outline-art-approved:disabled {
    color: #E1D35F;
    background-color: transparent; }
  .btn-outline-art-approved:not(:disabled):not(.disabled):active, .btn-outline-art-approved:not(:disabled):not(.disabled).active,
  .show > .btn-outline-art-approved.dropdown-toggle {
    color: #212529;
    background-color: #E1D35F;
    border-color: #E1D35F; }
    .btn-outline-art-approved:not(:disabled):not(.disabled):active:focus, .btn-outline-art-approved:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-art-approved.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 211, 95, 0.5); }

.btn-outline-production {
  color: #0F6C91;
  border-color: #0F6C91; }
  .btn-outline-production:hover {
    color: #fff;
    background-color: #0F6C91;
    border-color: #0F6C91; }
  .btn-outline-production:focus, .btn-outline-production.focus {
    box-shadow: 0 0 0 0.2rem rgba(15, 108, 145, 0.5); }
  .btn-outline-production.disabled, .btn-outline-production:disabled {
    color: #0F6C91;
    background-color: transparent; }
  .btn-outline-production:not(:disabled):not(.disabled):active, .btn-outline-production:not(:disabled):not(.disabled).active,
  .show > .btn-outline-production.dropdown-toggle {
    color: #fff;
    background-color: #0F6C91;
    border-color: #0F6C91; }
    .btn-outline-production:not(:disabled):not(.disabled):active:focus, .btn-outline-production:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-production.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(15, 108, 145, 0.5); }

.btn-outline-finance {
  color: #918A07;
  border-color: #918A07; }
  .btn-outline-finance:hover {
    color: #fff;
    background-color: #918A07;
    border-color: #918A07; }
  .btn-outline-finance:focus, .btn-outline-finance.focus {
    box-shadow: 0 0 0 0.2rem rgba(145, 138, 7, 0.5); }
  .btn-outline-finance.disabled, .btn-outline-finance:disabled {
    color: #918A07;
    background-color: transparent; }
  .btn-outline-finance:not(:disabled):not(.disabled):active, .btn-outline-finance:not(:disabled):not(.disabled).active,
  .show > .btn-outline-finance.dropdown-toggle {
    color: #fff;
    background-color: #918A07;
    border-color: #918A07; }
    .btn-outline-finance:not(:disabled):not(.disabled):active:focus, .btn-outline-finance:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-finance.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(145, 138, 7, 0.5); }

.btn-outline-shipped {
  color: #8A12BF;
  border-color: #8A12BF; }
  .btn-outline-shipped:hover {
    color: #fff;
    background-color: #8A12BF;
    border-color: #8A12BF; }
  .btn-outline-shipped:focus, .btn-outline-shipped.focus {
    box-shadow: 0 0 0 0.2rem rgba(138, 18, 191, 0.5); }
  .btn-outline-shipped.disabled, .btn-outline-shipped:disabled {
    color: #8A12BF;
    background-color: transparent; }
  .btn-outline-shipped:not(:disabled):not(.disabled):active, .btn-outline-shipped:not(:disabled):not(.disabled).active,
  .show > .btn-outline-shipped.dropdown-toggle {
    color: #fff;
    background-color: #8A12BF;
    border-color: #8A12BF; }
    .btn-outline-shipped:not(:disabled):not(.disabled):active:focus, .btn-outline-shipped:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-shipped.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(138, 18, 191, 0.5); }

.btn-outline-unique-blue {
  color: #009FDF;
  border-color: #009FDF; }
  .btn-outline-unique-blue:hover {
    color: #fff;
    background-color: #009FDF;
    border-color: #009FDF; }
  .btn-outline-unique-blue:focus, .btn-outline-unique-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 159, 223, 0.5); }
  .btn-outline-unique-blue.disabled, .btn-outline-unique-blue:disabled {
    color: #009FDF;
    background-color: transparent; }
  .btn-outline-unique-blue:not(:disabled):not(.disabled):active, .btn-outline-unique-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-unique-blue.dropdown-toggle {
    color: #fff;
    background-color: #009FDF;
    border-color: #009FDF; }
    .btn-outline-unique-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-unique-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-unique-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 159, 223, 0.5); }

.btn-outline-unique-blue-highlight {
  color: #00b6ff;
  border-color: #00b6ff; }
  .btn-outline-unique-blue-highlight:hover {
    color: #fff;
    background-color: #00b6ff;
    border-color: #00b6ff; }
  .btn-outline-unique-blue-highlight:focus, .btn-outline-unique-blue-highlight.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 182, 255, 0.5); }
  .btn-outline-unique-blue-highlight.disabled, .btn-outline-unique-blue-highlight:disabled {
    color: #00b6ff;
    background-color: transparent; }
  .btn-outline-unique-blue-highlight:not(:disabled):not(.disabled):active, .btn-outline-unique-blue-highlight:not(:disabled):not(.disabled).active,
  .show > .btn-outline-unique-blue-highlight.dropdown-toggle {
    color: #fff;
    background-color: #00b6ff;
    border-color: #00b6ff; }
    .btn-outline-unique-blue-highlight:not(:disabled):not(.disabled):active:focus, .btn-outline-unique-blue-highlight:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-unique-blue-highlight.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 182, 255, 0.5); }

.btn-outline-unique-text {
  color: #4d4d4f;
  border-color: #4d4d4f; }
  .btn-outline-unique-text:hover {
    color: #fff;
    background-color: #4d4d4f;
    border-color: #4d4d4f; }
  .btn-outline-unique-text:focus, .btn-outline-unique-text.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 77, 79, 0.5); }
  .btn-outline-unique-text.disabled, .btn-outline-unique-text:disabled {
    color: #4d4d4f;
    background-color: transparent; }
  .btn-outline-unique-text:not(:disabled):not(.disabled):active, .btn-outline-unique-text:not(:disabled):not(.disabled).active,
  .show > .btn-outline-unique-text.dropdown-toggle {
    color: #fff;
    background-color: #4d4d4f;
    border-color: #4d4d4f; }
    .btn-outline-unique-text:not(:disabled):not(.disabled):active:focus, .btn-outline-unique-text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-unique-text.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(77, 77, 79, 0.5); }

.btn-outline-unique-grey {
  color: #7C828A;
  border-color: #7C828A; }
  .btn-outline-unique-grey:hover {
    color: #fff;
    background-color: #7C828A;
    border-color: #7C828A; }
  .btn-outline-unique-grey:focus, .btn-outline-unique-grey.focus {
    box-shadow: 0 0 0 0.2rem rgba(124, 130, 138, 0.5); }
  .btn-outline-unique-grey.disabled, .btn-outline-unique-grey:disabled {
    color: #7C828A;
    background-color: transparent; }
  .btn-outline-unique-grey:not(:disabled):not(.disabled):active, .btn-outline-unique-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-unique-grey.dropdown-toggle {
    color: #fff;
    background-color: #7C828A;
    border-color: #7C828A; }
    .btn-outline-unique-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-unique-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-unique-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(124, 130, 138, 0.5); }

.btn-outline-unique-grey-light {
  color: #b1b1b1;
  border-color: #b1b1b1; }
  .btn-outline-unique-grey-light:hover {
    color: #212529;
    background-color: #b1b1b1;
    border-color: #b1b1b1; }
  .btn-outline-unique-grey-light:focus, .btn-outline-unique-grey-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(177, 177, 177, 0.5); }
  .btn-outline-unique-grey-light.disabled, .btn-outline-unique-grey-light:disabled {
    color: #b1b1b1;
    background-color: transparent; }
  .btn-outline-unique-grey-light:not(:disabled):not(.disabled):active, .btn-outline-unique-grey-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-unique-grey-light.dropdown-toggle {
    color: #212529;
    background-color: #b1b1b1;
    border-color: #b1b1b1; }
    .btn-outline-unique-grey-light:not(:disabled):not(.disabled):active:focus, .btn-outline-unique-grey-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-unique-grey-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(177, 177, 177, 0.5); }

.btn-outline-unique-red {
  color: #DC3545;
  border-color: #DC3545; }
  .btn-outline-unique-red:hover {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545; }
  .btn-outline-unique-red:focus, .btn-outline-unique-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-unique-red.disabled, .btn-outline-unique-red:disabled {
    color: #DC3545;
    background-color: transparent; }
  .btn-outline-unique-red:not(:disabled):not(.disabled):active, .btn-outline-unique-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-unique-red.dropdown-toggle {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545; }
    .btn-outline-unique-red:not(:disabled):not(.disabled):active:focus, .btn-outline-unique-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-unique-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-unique-red-highlight {
  color: #ba2d3a;
  border-color: #ba2d3a; }
  .btn-outline-unique-red-highlight:hover {
    color: #fff;
    background-color: #ba2d3a;
    border-color: #ba2d3a; }
  .btn-outline-unique-red-highlight:focus, .btn-outline-unique-red-highlight.focus {
    box-shadow: 0 0 0 0.2rem rgba(186, 45, 58, 0.5); }
  .btn-outline-unique-red-highlight.disabled, .btn-outline-unique-red-highlight:disabled {
    color: #ba2d3a;
    background-color: transparent; }
  .btn-outline-unique-red-highlight:not(:disabled):not(.disabled):active, .btn-outline-unique-red-highlight:not(:disabled):not(.disabled).active,
  .show > .btn-outline-unique-red-highlight.dropdown-toggle {
    color: #fff;
    background-color: #ba2d3a;
    border-color: #ba2d3a; }
    .btn-outline-unique-red-highlight:not(:disabled):not(.disabled):active:focus, .btn-outline-unique-red-highlight:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-unique-red-highlight.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(186, 45, 58, 0.5); }

.btn-outline-unique-green {
  color: #60af75;
  border-color: #60af75; }
  .btn-outline-unique-green:hover {
    color: #fff;
    background-color: #60af75;
    border-color: #60af75; }
  .btn-outline-unique-green:focus, .btn-outline-unique-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(96, 175, 117, 0.5); }
  .btn-outline-unique-green.disabled, .btn-outline-unique-green:disabled {
    color: #60af75;
    background-color: transparent; }
  .btn-outline-unique-green:not(:disabled):not(.disabled):active, .btn-outline-unique-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-unique-green.dropdown-toggle {
    color: #fff;
    background-color: #60af75;
    border-color: #60af75; }
    .btn-outline-unique-green:not(:disabled):not(.disabled):active:focus, .btn-outline-unique-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-unique-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(96, 175, 117, 0.5); }

.btn-outline-unique-grey-lightUltra {
  color: #f1f1f1;
  border-color: #f1f1f1; }
  .btn-outline-unique-grey-lightUltra:hover {
    color: #212529;
    background-color: #f1f1f1;
    border-color: #f1f1f1; }
  .btn-outline-unique-grey-lightUltra:focus, .btn-outline-unique-grey-lightUltra.focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5); }
  .btn-outline-unique-grey-lightUltra.disabled, .btn-outline-unique-grey-lightUltra:disabled {
    color: #f1f1f1;
    background-color: transparent; }
  .btn-outline-unique-grey-lightUltra:not(:disabled):not(.disabled):active, .btn-outline-unique-grey-lightUltra:not(:disabled):not(.disabled).active,
  .show > .btn-outline-unique-grey-lightUltra.dropdown-toggle {
    color: #212529;
    background-color: #f1f1f1;
    border-color: #f1f1f1; }
    .btn-outline-unique-grey-lightUltra:not(:disabled):not(.disabled):active:focus, .btn-outline-unique-grey-lightUltra:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-unique-grey-lightUltra.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5); }

.btn-outline-unique-silver {
  color: #999999;
  border-color: #999999; }
  .btn-outline-unique-silver:hover {
    color: #212529;
    background-color: #999999;
    border-color: #999999; }
  .btn-outline-unique-silver:focus, .btn-outline-unique-silver.focus {
    box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5); }
  .btn-outline-unique-silver.disabled, .btn-outline-unique-silver:disabled {
    color: #999999;
    background-color: transparent; }
  .btn-outline-unique-silver:not(:disabled):not(.disabled):active, .btn-outline-unique-silver:not(:disabled):not(.disabled).active,
  .show > .btn-outline-unique-silver.dropdown-toggle {
    color: #212529;
    background-color: #999999;
    border-color: #999999; }
    .btn-outline-unique-silver:not(:disabled):not(.disabled):active:focus, .btn-outline-unique-silver:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-unique-silver.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5); }

.btn-outline-unique-yellow {
  color: #dac302;
  border-color: #dac302; }
  .btn-outline-unique-yellow:hover {
    color: #212529;
    background-color: #dac302;
    border-color: #dac302; }
  .btn-outline-unique-yellow:focus, .btn-outline-unique-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(218, 195, 2, 0.5); }
  .btn-outline-unique-yellow.disabled, .btn-outline-unique-yellow:disabled {
    color: #dac302;
    background-color: transparent; }
  .btn-outline-unique-yellow:not(:disabled):not(.disabled):active, .btn-outline-unique-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-unique-yellow.dropdown-toggle {
    color: #212529;
    background-color: #dac302;
    border-color: #dac302; }
    .btn-outline-unique-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-unique-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-unique-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(218, 195, 2, 0.5); }

.btn-outline-unique-input {
  color: #4d4d4f;
  border-color: #4d4d4f; }
  .btn-outline-unique-input:hover {
    color: #fff;
    background-color: #4d4d4f;
    border-color: #4d4d4f; }
  .btn-outline-unique-input:focus, .btn-outline-unique-input.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 77, 79, 0.5); }
  .btn-outline-unique-input.disabled, .btn-outline-unique-input:disabled {
    color: #4d4d4f;
    background-color: transparent; }
  .btn-outline-unique-input:not(:disabled):not(.disabled):active, .btn-outline-unique-input:not(:disabled):not(.disabled).active,
  .show > .btn-outline-unique-input.dropdown-toggle {
    color: #fff;
    background-color: #4d4d4f;
    border-color: #4d4d4f; }
    .btn-outline-unique-input:not(:disabled):not(.disabled):active:focus, .btn-outline-unique-input:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-unique-input.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(77, 77, 79, 0.5); }

.btn-link {
  font-weight: 400;
  color: #009FDF;
  text-decoration: none; }
  .btn-link:hover {
    color: #006893;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.75rem 2rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

.btn-edit-artwork {
  display: block;
  margin-top: 2rem;
  width: 4rem;
  height: 2rem; }

.btn-approve {
  color: #60af75;
  background-color: #fff;
  border-color: #60af75; }
  .btn-approve:hover {
    background-color: #60af75 !important;
    color: #fff; }

.btn-reject {
  color: #DC3545;
  background-color: #fff;
  border-color: #DC3545; }
  .btn-reject:hover {
    background-color: #DC3545 !important;
    color: #fff; }

.btn-red {
  color: #fff;
  border-color: #DC3545;
  background-color: #DC3545 !important; }
  .btn-red:hover {
    color: #fff;
    background-color: #ba2d3a !important; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #4d4d4f;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #4470C3; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1.5rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #4470C3;
    background-color: #4470C3; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(68, 112, 195, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #a5bae2; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #cbd7ee;
    border-color: #cbd7ee; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #4470C3;
  background-color: #4470C3; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 174, 239, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 174, 239, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 174, 239, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 174, 239, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #a5bae2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 112, 195, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1.5rem + 2px);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  font-size: 1.125rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #a5bae2;
    box-shadow: 0 0 0 0.2rem rgba(68, 112, 195, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(68, 112, 195, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(68, 112, 195, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(68, 112, 195, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #4470C3;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #cbd7ee; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #4470C3;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #cbd7ee; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #4470C3;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #cbd7ee; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs.noBorder {
    border-bottom: 0; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4470C3; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand.constant-color:hover {
    color: #009FDF; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
    margin: 0.28rem 0;
    padding: 0; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.2rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  background: rgba(0, 182, 255, 0.18);
  border: 1px solid #dee2e6;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #009FDF;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #006893;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 112, 195, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4470C3;
  border-color: #4470C3; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #00aeef; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0089bc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 174, 239, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #f4f4f4; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #dbdbdb; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5); }

.badge-success {
  color: #fff;
  background-color: #091426; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: black; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(9, 20, 38, 0.5); }

.badge-info {
  color: #212529;
  background-color: #6FA4CA; }
  a.badge-info:hover, a.badge-info:focus {
    color: #212529;
    background-color: #4a8cbc; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(111, 164, 202, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #F5A623; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #db8c0a; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 166, 35, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #DC3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.badge-placed {
  color: #fff;
  background-color: #AB3511; }
  a.badge-placed:hover, a.badge-placed:focus {
    color: #fff;
    background-color: #7d270c; }
  a.badge-placed:focus, a.badge-placed.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(171, 53, 17, 0.5); }

.badge-art-prepared {
  color: #fff;
  background-color: #E27938; }
  a.badge-art-prepared:hover, a.badge-art-prepared:focus {
    color: #fff;
    background-color: #ca5f1d; }
  a.badge-art-prepared:focus, a.badge-art-prepared.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(226, 121, 56, 0.5); }

.badge-art-approved {
  color: #212529;
  background-color: #E1D35F; }
  a.badge-art-approved:hover, a.badge-art-approved:focus {
    color: #212529;
    background-color: #d9c734; }
  a.badge-art-approved:focus, a.badge-art-approved.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(225, 211, 95, 0.5); }

.badge-production {
  color: #fff;
  background-color: #0F6C91; }
  a.badge-production:hover, a.badge-production:focus {
    color: #fff;
    background-color: #0a4a63; }
  a.badge-production:focus, a.badge-production.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(15, 108, 145, 0.5); }

.badge-finance {
  color: #fff;
  background-color: #918A07; }
  a.badge-finance:hover, a.badge-finance:focus {
    color: #fff;
    background-color: #605c05; }
  a.badge-finance:focus, a.badge-finance.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(145, 138, 7, 0.5); }

.badge-shipped {
  color: #fff;
  background-color: #8A12BF; }
  a.badge-shipped:hover, a.badge-shipped:focus {
    color: #fff;
    background-color: #680e90; }
  a.badge-shipped:focus, a.badge-shipped.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(138, 18, 191, 0.5); }

.badge-unique-blue {
  color: #fff;
  background-color: #009FDF; }
  a.badge-unique-blue:hover, a.badge-unique-blue:focus {
    color: #fff;
    background-color: #007bac; }
  a.badge-unique-blue:focus, a.badge-unique-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 159, 223, 0.5); }

.badge-unique-blue-highlight {
  color: #fff;
  background-color: #00b6ff; }
  a.badge-unique-blue-highlight:hover, a.badge-unique-blue-highlight:focus {
    color: #fff;
    background-color: #0092cc; }
  a.badge-unique-blue-highlight:focus, a.badge-unique-blue-highlight.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 182, 255, 0.5); }

.badge-unique-text {
  color: #fff;
  background-color: #4d4d4f; }
  a.badge-unique-text:hover, a.badge-unique-text:focus {
    color: #fff;
    background-color: #343435; }
  a.badge-unique-text:focus, a.badge-unique-text.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(77, 77, 79, 0.5); }

.badge-unique-grey {
  color: #fff;
  background-color: #7C828A; }
  a.badge-unique-grey:hover, a.badge-unique-grey:focus {
    color: #fff;
    background-color: #64696f; }
  a.badge-unique-grey:focus, a.badge-unique-grey.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(124, 130, 138, 0.5); }

.badge-unique-grey-light {
  color: #212529;
  background-color: #b1b1b1; }
  a.badge-unique-grey-light:hover, a.badge-unique-grey-light:focus {
    color: #212529;
    background-color: #989898; }
  a.badge-unique-grey-light:focus, a.badge-unique-grey-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(177, 177, 177, 0.5); }

.badge-unique-red {
  color: #fff;
  background-color: #DC3545; }
  a.badge-unique-red:hover, a.badge-unique-red:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-unique-red:focus, a.badge-unique-red.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-unique-red-highlight {
  color: #fff;
  background-color: #ba2d3a; }
  a.badge-unique-red-highlight:hover, a.badge-unique-red-highlight:focus {
    color: #fff;
    background-color: #91232d; }
  a.badge-unique-red-highlight:focus, a.badge-unique-red-highlight.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(186, 45, 58, 0.5); }

.badge-unique-green {
  color: #fff;
  background-color: #60af75; }
  a.badge-unique-green:hover, a.badge-unique-green:focus {
    color: #fff;
    background-color: #4a925d; }
  a.badge-unique-green:focus, a.badge-unique-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(96, 175, 117, 0.5); }

.badge-unique-grey-lightUltra {
  color: #212529;
  background-color: #f1f1f1; }
  a.badge-unique-grey-lightUltra:hover, a.badge-unique-grey-lightUltra:focus {
    color: #212529;
    background-color: #d8d8d8; }
  a.badge-unique-grey-lightUltra:focus, a.badge-unique-grey-lightUltra.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5); }

.badge-unique-silver {
  color: #212529;
  background-color: #999999; }
  a.badge-unique-silver:hover, a.badge-unique-silver:focus {
    color: #212529;
    background-color: gray; }
  a.badge-unique-silver:focus, a.badge-unique-silver.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5); }

.badge-unique-yellow {
  color: #212529;
  background-color: #dac302; }
  a.badge-unique-yellow:hover, a.badge-unique-yellow:focus {
    color: #212529;
    background-color: #a79602; }
  a.badge-unique-yellow:focus, a.badge-unique-yellow.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(218, 195, 2, 0.5); }

.badge-unique-input {
  color: #fff;
  background-color: #4d4d4f; }
  a.badge-unique-input:hover, a.badge-unique-input:focus {
    color: #fff;
    background-color: #343435; }
  a.badge-unique-input:focus, a.badge-unique-input.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(77, 77, 79, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.2rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #005a7c;
  background-color: #cceffc;
  border-color: #b8e8fb; }
  .alert-primary hr {
    border-top-color: #a0e0fa; }
  .alert-primary .alert-link {
    color: #003549; }

.alert-secondary {
  color: #7f7f7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc; }
  .alert-secondary hr {
    border-top-color: #efefef; }
  .alert-secondary .alert-link {
    color: #666666; }

.alert-success {
  color: #050a14;
  background-color: #ced0d4;
  border-color: #babdc2; }
  .alert-success hr {
    border-top-color: #acb0b6; }
  .alert-success .alert-link {
    color: black; }

.alert-info {
  color: #3a5569;
  background-color: #e2edf4;
  border-color: #d7e6f0; }
  .alert-info hr {
    border-top-color: #c4dae9; }
  .alert-info .alert-link {
    color: #283a48; }

.alert-warning {
  color: #7f5612;
  background-color: #fdedd3;
  border-color: #fce6c1; }
  .alert-warning hr {
    border-top-color: #fbdca9; }
  .alert-warning .alert-link {
    color: #52380c; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.alert-placed {
  color: #591c09;
  background-color: #eed7cf;
  border-color: #e7c6bc; }
  .alert-placed hr {
    border-top-color: #e0b6a9; }
  .alert-placed .alert-link {
    color: #2b0d04; }

.alert-art-prepared {
  color: #763f1d;
  background-color: #f9e4d7;
  border-color: #f7d9c7; }
  .alert-art-prepared hr {
    border-top-color: #f4cab1; }
  .alert-art-prepared .alert-link {
    color: #4d2913; }

.alert-art-approved {
  color: #756e31;
  background-color: #f9f6df;
  border-color: #f7f3d2; }
  .alert-art-approved hr {
    border-top-color: #f3edbc; }
  .alert-art-approved .alert-link {
    color: #514c22; }

.alert-production {
  color: #08384b;
  background-color: #cfe2e9;
  border-color: #bcd6e0; }
  .alert-production hr {
    border-top-color: #abcbd8; }
  .alert-production .alert-link {
    color: #03161d; }

.alert-finance {
  color: #4b4804;
  background-color: #e9e8cd;
  border-color: #e0deba; }
  .alert-finance hr {
    border-top-color: #d8d6a8; }
  .alert-finance .alert-link {
    color: #1b1a01; }

.alert-shipped {
  color: #480963;
  background-color: #e8d0f2;
  border-color: #debded; }
  .alert-shipped hr {
    border-top-color: #d4a9e8; }
  .alert-shipped .alert-link {
    color: #260534; }

.alert-unique-blue {
  color: #005374;
  background-color: #ccecf9;
  border-color: #b8e4f6; }
  .alert-unique-blue hr {
    border-top-color: #a1dbf3; }
  .alert-unique-blue .alert-link {
    color: #002f41; }

.alert-unique-blue-highlight {
  color: #005f85;
  background-color: #ccf0ff;
  border-color: #b8ebff; }
  .alert-unique-blue-highlight hr {
    border-top-color: #9fe4ff; }
  .alert-unique-blue-highlight .alert-link {
    color: #003b52; }

.alert-unique-text {
  color: #282829;
  background-color: #dbdbdc;
  border-color: #cdcdce; }
  .alert-unique-text hr {
    border-top-color: #c0c0c1; }
  .alert-unique-text .alert-link {
    color: #0f0f0f; }

.alert-unique-grey {
  color: #404448;
  background-color: #e5e6e8;
  border-color: #dadcde; }
  .alert-unique-grey hr {
    border-top-color: #cdcfd2; }
  .alert-unique-grey .alert-link {
    color: #282b2d; }

.alert-unique-grey-light {
  color: #5c5c5c;
  background-color: #efefef;
  border-color: #e9e9e9; }
  .alert-unique-grey-light hr {
    border-top-color: gainsboro; }
  .alert-unique-grey-light .alert-link {
    color: #434343; }

.alert-unique-red {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-unique-red hr {
    border-top-color: #f1b0b7; }
  .alert-unique-red .alert-link {
    color: #491217; }

.alert-unique-red-highlight {
  color: #61171e;
  background-color: #f1d5d8;
  border-color: #ecc4c8; }
  .alert-unique-red-highlight hr {
    border-top-color: #e6b1b6; }
  .alert-unique-red-highlight .alert-link {
    color: #380d11; }

.alert-unique-green {
  color: #325b3d;
  background-color: #dfefe3;
  border-color: #d2e9d8; }
  .alert-unique-green hr {
    border-top-color: #c1e1c9; }
  .alert-unique-green .alert-link {
    color: #203a27; }

.alert-unique-grey-lightUltra {
  color: #7d7d7d;
  background-color: #fcfcfc;
  border-color: #fbfbfb; }
  .alert-unique-grey-lightUltra hr {
    border-top-color: #eeeeee; }
  .alert-unique-grey-lightUltra .alert-link {
    color: #646464; }

.alert-unique-silver {
  color: #505050;
  background-color: #ebebeb;
  border-color: #e2e2e2; }
  .alert-unique-silver hr {
    border-top-color: #d5d5d5; }
  .alert-unique-silver .alert-link {
    color: #373737; }

.alert-unique-yellow {
  color: #716501;
  background-color: #f8f3cc;
  border-color: #f5eeb8; }
  .alert-unique-yellow hr {
    border-top-color: #f2e9a2; }
  .alert-unique-yellow .alert-link {
    color: #3e3801; }

.alert-unique-input {
  color: #282829;
  background-color: #dbdbdc;
  border-color: #cdcdce; }
  .alert-unique-input hr {
    border-top-color: #c0c0c1; }
  .alert-unique-input .alert-link {
    color: #0f0f0f; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #00aeef;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #4d4d4f;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #4470C3;
    border-color: #4470C3; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #005a7c;
  background-color: #b8e8fb; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #005a7c;
    background-color: #a0e0fa; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #005a7c;
    border-color: #005a7c; }

.list-group-item-secondary {
  color: #7f7f7f;
  background-color: #fcfcfc; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #7f7f7f;
    background-color: #efefef; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #7f7f7f;
    border-color: #7f7f7f; }

.list-group-item-success {
  color: #050a14;
  background-color: #babdc2; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #050a14;
    background-color: #acb0b6; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #050a14;
    border-color: #050a14; }

.list-group-item-info {
  color: #3a5569;
  background-color: #d7e6f0; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #3a5569;
    background-color: #c4dae9; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #3a5569;
    border-color: #3a5569; }

.list-group-item-warning {
  color: #7f5612;
  background-color: #fce6c1; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7f5612;
    background-color: #fbdca9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7f5612;
    border-color: #7f5612; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.list-group-item-placed {
  color: #591c09;
  background-color: #e7c6bc; }
  .list-group-item-placed.list-group-item-action:hover, .list-group-item-placed.list-group-item-action:focus {
    color: #591c09;
    background-color: #e0b6a9; }
  .list-group-item-placed.list-group-item-action.active {
    color: #fff;
    background-color: #591c09;
    border-color: #591c09; }

.list-group-item-art-prepared {
  color: #763f1d;
  background-color: #f7d9c7; }
  .list-group-item-art-prepared.list-group-item-action:hover, .list-group-item-art-prepared.list-group-item-action:focus {
    color: #763f1d;
    background-color: #f4cab1; }
  .list-group-item-art-prepared.list-group-item-action.active {
    color: #fff;
    background-color: #763f1d;
    border-color: #763f1d; }

.list-group-item-art-approved {
  color: #756e31;
  background-color: #f7f3d2; }
  .list-group-item-art-approved.list-group-item-action:hover, .list-group-item-art-approved.list-group-item-action:focus {
    color: #756e31;
    background-color: #f3edbc; }
  .list-group-item-art-approved.list-group-item-action.active {
    color: #fff;
    background-color: #756e31;
    border-color: #756e31; }

.list-group-item-production {
  color: #08384b;
  background-color: #bcd6e0; }
  .list-group-item-production.list-group-item-action:hover, .list-group-item-production.list-group-item-action:focus {
    color: #08384b;
    background-color: #abcbd8; }
  .list-group-item-production.list-group-item-action.active {
    color: #fff;
    background-color: #08384b;
    border-color: #08384b; }

.list-group-item-finance {
  color: #4b4804;
  background-color: #e0deba; }
  .list-group-item-finance.list-group-item-action:hover, .list-group-item-finance.list-group-item-action:focus {
    color: #4b4804;
    background-color: #d8d6a8; }
  .list-group-item-finance.list-group-item-action.active {
    color: #fff;
    background-color: #4b4804;
    border-color: #4b4804; }

.list-group-item-shipped {
  color: #480963;
  background-color: #debded; }
  .list-group-item-shipped.list-group-item-action:hover, .list-group-item-shipped.list-group-item-action:focus {
    color: #480963;
    background-color: #d4a9e8; }
  .list-group-item-shipped.list-group-item-action.active {
    color: #fff;
    background-color: #480963;
    border-color: #480963; }

.list-group-item-unique-blue {
  color: #005374;
  background-color: #b8e4f6; }
  .list-group-item-unique-blue.list-group-item-action:hover, .list-group-item-unique-blue.list-group-item-action:focus {
    color: #005374;
    background-color: #a1dbf3; }
  .list-group-item-unique-blue.list-group-item-action.active {
    color: #fff;
    background-color: #005374;
    border-color: #005374; }

.list-group-item-unique-blue-highlight {
  color: #005f85;
  background-color: #b8ebff; }
  .list-group-item-unique-blue-highlight.list-group-item-action:hover, .list-group-item-unique-blue-highlight.list-group-item-action:focus {
    color: #005f85;
    background-color: #9fe4ff; }
  .list-group-item-unique-blue-highlight.list-group-item-action.active {
    color: #fff;
    background-color: #005f85;
    border-color: #005f85; }

.list-group-item-unique-text {
  color: #282829;
  background-color: #cdcdce; }
  .list-group-item-unique-text.list-group-item-action:hover, .list-group-item-unique-text.list-group-item-action:focus {
    color: #282829;
    background-color: #c0c0c1; }
  .list-group-item-unique-text.list-group-item-action.active {
    color: #fff;
    background-color: #282829;
    border-color: #282829; }

.list-group-item-unique-grey {
  color: #404448;
  background-color: #dadcde; }
  .list-group-item-unique-grey.list-group-item-action:hover, .list-group-item-unique-grey.list-group-item-action:focus {
    color: #404448;
    background-color: #cdcfd2; }
  .list-group-item-unique-grey.list-group-item-action.active {
    color: #fff;
    background-color: #404448;
    border-color: #404448; }

.list-group-item-unique-grey-light {
  color: #5c5c5c;
  background-color: #e9e9e9; }
  .list-group-item-unique-grey-light.list-group-item-action:hover, .list-group-item-unique-grey-light.list-group-item-action:focus {
    color: #5c5c5c;
    background-color: gainsboro; }
  .list-group-item-unique-grey-light.list-group-item-action.active {
    color: #fff;
    background-color: #5c5c5c;
    border-color: #5c5c5c; }

.list-group-item-unique-red {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-unique-red.list-group-item-action:hover, .list-group-item-unique-red.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-unique-red.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-unique-red-highlight {
  color: #61171e;
  background-color: #ecc4c8; }
  .list-group-item-unique-red-highlight.list-group-item-action:hover, .list-group-item-unique-red-highlight.list-group-item-action:focus {
    color: #61171e;
    background-color: #e6b1b6; }
  .list-group-item-unique-red-highlight.list-group-item-action.active {
    color: #fff;
    background-color: #61171e;
    border-color: #61171e; }

.list-group-item-unique-green {
  color: #325b3d;
  background-color: #d2e9d8; }
  .list-group-item-unique-green.list-group-item-action:hover, .list-group-item-unique-green.list-group-item-action:focus {
    color: #325b3d;
    background-color: #c1e1c9; }
  .list-group-item-unique-green.list-group-item-action.active {
    color: #fff;
    background-color: #325b3d;
    border-color: #325b3d; }

.list-group-item-unique-grey-lightUltra {
  color: #7d7d7d;
  background-color: #fbfbfb; }
  .list-group-item-unique-grey-lightUltra.list-group-item-action:hover, .list-group-item-unique-grey-lightUltra.list-group-item-action:focus {
    color: #7d7d7d;
    background-color: #eeeeee; }
  .list-group-item-unique-grey-lightUltra.list-group-item-action.active {
    color: #fff;
    background-color: #7d7d7d;
    border-color: #7d7d7d; }

.list-group-item-unique-silver {
  color: #505050;
  background-color: #e2e2e2; }
  .list-group-item-unique-silver.list-group-item-action:hover, .list-group-item-unique-silver.list-group-item-action:focus {
    color: #505050;
    background-color: #d5d5d5; }
  .list-group-item-unique-silver.list-group-item-action.active {
    color: #fff;
    background-color: #505050;
    border-color: #505050; }

.list-group-item-unique-yellow {
  color: #716501;
  background-color: #f5eeb8; }
  .list-group-item-unique-yellow.list-group-item-action:hover, .list-group-item-unique-yellow.list-group-item-action:focus {
    color: #716501;
    background-color: #f2e9a2; }
  .list-group-item-unique-yellow.list-group-item-action.active {
    color: #fff;
    background-color: #716501;
    border-color: #716501; }

.list-group-item-unique-input {
  color: #282829;
  background-color: #cdcdce; }
  .list-group-item-unique-input.list-group-item-action:hover, .list-group-item-unique-input.list-group-item-action:focus {
    color: #282829;
    background-color: #c0c0c1; }
  .list-group-item-unique-input.list-group-item-action.active {
    color: #fff;
    background-color: #282829;
    border-color: #282829; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 99999;
  display: block;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.2rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.2rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.2rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #4d4d4f; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #00aeef !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0089bc !important; }

.bg-secondary {
  background-color: #f4f4f4 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #dbdbdb !important; }

.bg-success {
  background-color: #091426 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: black !important; }

.bg-info {
  background-color: #6FA4CA !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #4a8cbc !important; }

.bg-warning {
  background-color: #F5A623 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #db8c0a !important; }

.bg-danger {
  background-color: #DC3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-placed {
  background-color: #AB3511 !important; }

a.bg-placed:hover, a.bg-placed:focus,
button.bg-placed:hover,
button.bg-placed:focus {
  background-color: #7d270c !important; }

.bg-art-prepared {
  background-color: #E27938 !important; }

a.bg-art-prepared:hover, a.bg-art-prepared:focus,
button.bg-art-prepared:hover,
button.bg-art-prepared:focus {
  background-color: #ca5f1d !important; }

.bg-art-approved {
  background-color: #E1D35F !important; }

a.bg-art-approved:hover, a.bg-art-approved:focus,
button.bg-art-approved:hover,
button.bg-art-approved:focus {
  background-color: #d9c734 !important; }

.bg-production {
  background-color: #0F6C91 !important; }

a.bg-production:hover, a.bg-production:focus,
button.bg-production:hover,
button.bg-production:focus {
  background-color: #0a4a63 !important; }

.bg-finance {
  background-color: #918A07 !important; }

a.bg-finance:hover, a.bg-finance:focus,
button.bg-finance:hover,
button.bg-finance:focus {
  background-color: #605c05 !important; }

.bg-shipped {
  background-color: #8A12BF !important; }

a.bg-shipped:hover, a.bg-shipped:focus,
button.bg-shipped:hover,
button.bg-shipped:focus {
  background-color: #680e90 !important; }

.bg-unique-blue {
  background-color: #009FDF !important; }

a.bg-unique-blue:hover, a.bg-unique-blue:focus,
button.bg-unique-blue:hover,
button.bg-unique-blue:focus {
  background-color: #007bac !important; }

.bg-unique-blue-highlight {
  background-color: #00b6ff !important; }

a.bg-unique-blue-highlight:hover, a.bg-unique-blue-highlight:focus,
button.bg-unique-blue-highlight:hover,
button.bg-unique-blue-highlight:focus {
  background-color: #0092cc !important; }

.bg-unique-text {
  background-color: #4d4d4f !important; }

a.bg-unique-text:hover, a.bg-unique-text:focus,
button.bg-unique-text:hover,
button.bg-unique-text:focus {
  background-color: #343435 !important; }

.bg-unique-grey {
  background-color: #7C828A !important; }

a.bg-unique-grey:hover, a.bg-unique-grey:focus,
button.bg-unique-grey:hover,
button.bg-unique-grey:focus {
  background-color: #64696f !important; }

.bg-unique-grey-light {
  background-color: #b1b1b1 !important; }

a.bg-unique-grey-light:hover, a.bg-unique-grey-light:focus,
button.bg-unique-grey-light:hover,
button.bg-unique-grey-light:focus {
  background-color: #989898 !important; }

.bg-unique-red {
  background-color: #DC3545 !important; }

a.bg-unique-red:hover, a.bg-unique-red:focus,
button.bg-unique-red:hover,
button.bg-unique-red:focus {
  background-color: #bd2130 !important; }

.bg-unique-red-highlight {
  background-color: #ba2d3a !important; }

a.bg-unique-red-highlight:hover, a.bg-unique-red-highlight:focus,
button.bg-unique-red-highlight:hover,
button.bg-unique-red-highlight:focus {
  background-color: #91232d !important; }

.bg-unique-green {
  background-color: #60af75 !important; }

a.bg-unique-green:hover, a.bg-unique-green:focus,
button.bg-unique-green:hover,
button.bg-unique-green:focus {
  background-color: #4a925d !important; }

.bg-unique-grey-lightUltra {
  background-color: #f1f1f1 !important; }

a.bg-unique-grey-lightUltra:hover, a.bg-unique-grey-lightUltra:focus,
button.bg-unique-grey-lightUltra:hover,
button.bg-unique-grey-lightUltra:focus {
  background-color: #d8d8d8 !important; }

.bg-unique-silver {
  background-color: #999999 !important; }

a.bg-unique-silver:hover, a.bg-unique-silver:focus,
button.bg-unique-silver:hover,
button.bg-unique-silver:focus {
  background-color: gray !important; }

.bg-unique-yellow {
  background-color: #dac302 !important; }

a.bg-unique-yellow:hover, a.bg-unique-yellow:focus,
button.bg-unique-yellow:hover,
button.bg-unique-yellow:focus {
  background-color: #a79602 !important; }

.bg-unique-input {
  background-color: #4d4d4f !important; }

a.bg-unique-input:hover, a.bg-unique-input:focus,
button.bg-unique-input:hover,
button.bg-unique-input:focus {
  background-color: #343435 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #00aeef !important; }

.border-secondary {
  border-color: #f4f4f4 !important; }

.border-success {
  border-color: #091426 !important; }

.border-info {
  border-color: #6FA4CA !important; }

.border-warning {
  border-color: #F5A623 !important; }

.border-danger {
  border-color: #DC3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-placed {
  border-color: #AB3511 !important; }

.border-art-prepared {
  border-color: #E27938 !important; }

.border-art-approved {
  border-color: #E1D35F !important; }

.border-production {
  border-color: #0F6C91 !important; }

.border-finance {
  border-color: #918A07 !important; }

.border-shipped {
  border-color: #8A12BF !important; }

.border-unique-blue {
  border-color: #009FDF !important; }

.border-unique-blue-highlight {
  border-color: #00b6ff !important; }

.border-unique-text {
  border-color: #4d4d4f !important; }

.border-unique-grey {
  border-color: #7C828A !important; }

.border-unique-grey-light {
  border-color: #b1b1b1 !important; }

.border-unique-red {
  border-color: #DC3545 !important; }

.border-unique-red-highlight {
  border-color: #ba2d3a !important; }

.border-unique-green {
  border-color: #60af75 !important; }

.border-unique-grey-lightUltra {
  border-color: #f1f1f1 !important; }

.border-unique-silver {
  border-color: #999999 !important; }

.border-unique-yellow {
  border-color: #dac302 !important; }

.border-unique-input {
  border-color: #4d4d4f !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.2rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #00aeef !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0076a3 !important; }

.text-secondary {
  color: #f4f4f4 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #cecece !important; }

.text-success {
  color: #091426 !important; }

a.text-success:hover, a.text-success:focus {
  color: black !important; }

.text-info {
  color: #6FA4CA !important; }

a.text-info:hover, a.text-info:focus {
  color: #407fad !important; }

.text-warning {
  color: #F5A623 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #c37d09 !important; }

.text-danger {
  color: #DC3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-placed {
  color: #AB3511 !important; }

a.text-placed:hover, a.text-placed:focus {
  color: #651f0a !important; }

.text-art-prepared {
  color: #E27938 !important; }

a.text-art-prepared:hover, a.text-art-prepared:focus {
  color: #b3551a !important; }

.text-art-approved {
  color: #E1D35F !important; }

a.text-art-approved:hover, a.text-art-approved:focus {
  color: #cdbb26 !important; }

.text-production {
  color: #0F6C91 !important; }

a.text-production:hover, a.text-production:focus {
  color: #08384c !important; }

.text-finance {
  color: #918A07 !important; }

a.text-finance:hover, a.text-finance:focus {
  color: #484503 !important; }

.text-shipped {
  color: #8A12BF !important; }

a.text-shipped:hover, a.text-shipped:focus {
  color: #570b79 !important; }

.text-unique-blue {
  color: #009FDF !important; }

a.text-unique-blue:hover, a.text-unique-blue:focus {
  color: #006893 !important; }

.text-unique-blue-highlight {
  color: #00b6ff !important; }

a.text-unique-blue-highlight:hover, a.text-unique-blue-highlight:focus {
  color: #007fb3 !important; }

.text-unique-text {
  color: #4d4d4f !important; }

a.text-unique-text:hover, a.text-unique-text:focus {
  color: #272728 !important; }

.text-unique-grey {
  color: #7C828A !important; }

a.text-unique-grey:hover, a.text-unique-grey:focus {
  color: #585c62 !important; }

.text-unique-grey-light {
  color: #b1b1b1 !important; }

a.text-unique-grey-light:hover, a.text-unique-grey-light:focus {
  color: #8b8b8b !important; }

.text-unique-red {
  color: #DC3545 !important; }

a.text-unique-red:hover, a.text-unique-red:focus {
  color: #a71d2a !important; }

.text-unique-red-highlight {
  color: #ba2d3a !important; }

a.text-unique-red-highlight:hover, a.text-unique-red-highlight:focus {
  color: #7c1e27 !important; }

.text-unique-green {
  color: #60af75 !important; }

a.text-unique-green:hover, a.text-unique-green:focus {
  color: #418152 !important; }

.text-unique-grey-lightUltra {
  color: #f1f1f1 !important; }

a.text-unique-grey-lightUltra:hover, a.text-unique-grey-lightUltra:focus {
  color: #cbcbcb !important; }

.text-unique-silver {
  color: #999999 !important; }

a.text-unique-silver:hover, a.text-unique-silver:focus {
  color: #737373 !important; }

.text-unique-yellow {
  color: #dac302 !important; }

a.text-unique-yellow:hover, a.text-unique-yellow:focus {
  color: #8e7f01 !important; }

.text-unique-input {
  color: #4d4d4f !important; }

a.text-unique-input:hover, a.text-unique-input:focus {
  color: #272728 !important; }

.text-body {
  color: #4d4d4f !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* ==========================================================================
   Base
	========================================================================== */
/* General Layout
   ========================================================================== */
html,
body {
  height: 100%;
  min-height: 100vh;
  overscroll-behavior: none;
  min-width: 1024px;
  color: black !important; }

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  min-width: 1024px; }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  padding-right: 2rem;
  padding-left: 2rem; }

#page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px; }
  #page.terms-and-conditions {
    padding-top: 0px; }

section.site-content {
  align-self: stretch;
  flex: 1 0 auto; }

/* Typography
	========================================================================== */
h2 {
  font-size: 1.125rem;
  text-transform: uppercase;
  font-weight: 600; }

h3 {
  font-size: 0.875rem;
  font-weight: 600; }

h4.PageTitle {
  color: #009FDF;
  margin-top: 2rem;
  margin-bottom: 2rem; }
  h4.PageTitle .page-parent {
    font-weight: 600; }
  h4.PageTitle .page-current {
    font-weight: 500; }

p {
  font-size: 0.86rem; }

.nobreak {
  white-space: nowrap; }

select option:disabled {
  color: #aaaaaa; }

.d-space {
  margin-right: auto !important; }

.alert-window {
  z-index: 99999999999 !important; }
  .alert-window .alert-window-title {
    color: #009FDF !important; }
  .alert-window .alert-window-content {
    color: black !important; }
  .alert-window .alert-window-primary-btn {
    color: #fff;
    background-color: #009FDF !important;
    border-color: #009FDF !important;
    margin-right: 1rem !important; }
    .alert-window .alert-window-primary-btn:hover {
      background-color: #00b6ff !important;
      border-color: #00b6ff !important; }
  .alert-window .alert-window-secondary-btn {
    color: #fff;
    background-color: #7C828A !important;
    border-color: #7C828A !important; }
    .alert-window .alert-window-secondary-btn:hover {
      background-color: #b1b1b1 !important;
      border-color: #b1b1b1 !important; }

.file-upload-btn {
  background-color: #00b6ff;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 17px;
  padding-right: 17px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600; }
  .file-upload-btn:hover {
    background-color: #009FDF;
    color: #fff; }
  .file-upload-btn input {
    display: none; }

.btn-group-lg > .btn, .btn-lg, .btn-group-lg > .btn {
  padding: .75rem 5rem;
  font-weight: bold; }

.btn {
  font-weight: 600; }
  .btn:disabled {
    opacity: 0.4 !important; }

.btn-outline-primary:hover {
  border-color: #00aeef !important; }

.btn-sm, .btn-group-sm > .btn {
  font-size: 0.875rem; }

.hidden-focus-button {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border-width: 0 !important;
  opacity: 0 !important;
  cursor: default !important; }

.select-all-btn {
  margin-bottom: 8px;
  display: block;
  width: 100%; }
  .select-all-btn:disabled {
    pointer-events: none; }

.add-entity-btn {
  margin-bottom: 10px; }

.form-group label {
  font-size: 0.875rem;
  margin-bottom: 0; }

#searchForm .form-row > .col-left-none {
  padding-left: 0px !important; }

.bs-searchbox .form-control {
  border-radius: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0; }

.form-control.is-invalid, .was-validated .form-control:invalid {
  border: 2px solid #DC3545; }

.bootstrap-select > .dropdown-toggle {
  background: transparent;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  padding: 0.3rem 0.75rem 0.3rem 1rem !important;
  height: 100%;
  background-color: transparent;
  position: relative;
  z-index: 2;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 400;
  background-color: white;
  color: #4d4d4f; }
  .bootstrap-select > .dropdown-toggle:active, .bootstrap-select > .dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(68, 112, 195, 0.25);
    outline: 0 !important; }
  .bootstrap-select > .dropdown-toggle:after {
    font-family: 'Font Awesome 5 Pro' !important;
    content: "\f107";
    speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 1em;
    width: 1rem;
    height: 1rem;
    border: 0; }
  .bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.dropdown-toggle.bs-placeholder:active, .bootstrap-select > .dropdown-toggle.dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.dropdown-toggle.bs-placeholder:hover {
    color: #4d4d4f; }
  .bootstrap-select > .dropdown-toggle .filter-option .filter-option-inner-inner {
    display: flex;
    align-items: center; }
  .bootstrap-select > .dropdown-toggle .filter-option small {
    color: #4d4d4f; }

.bootstrap-select.disabled > .dropdown-toggle {
  cursor: not-allowed; }
  .bootstrap-select.disabled > .dropdown-toggle:after {
    color: #6c757d;
    opacity: 0.65; }

.bootstrap-select.show > .btn-light.dropdown-toggle {
  background: transparent;
  border: 2px solid #009FDF;
  box-shadow: none !important;
  outline: 0 !important; }
  .bootstrap-select.show > .btn-light.dropdown-toggle:active, .bootstrap-select.show > .btn-light.dropdown-toggle:focus {
    outline: 0 !important; }

.bootstrap-select .bs-ok-default:after {
  font-family: 'Font Awesome Pro' !important;
  content: "\f107";
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #4d4d4f;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  border: 0;
  width: 1.5rem;
  height: 1.5rem; }

.bootstrap-select [class*="uci-"], .bootstrap-select [class^="uci-"] {
  font-size: 1.5rem;
  font-weight: bolder;
  margin-right: 2%; }

.bootstrap-select .dropdown-item.selected {
  color: #4d4d4f !important;
  background-color: rgba(77, 77, 79, 0.15); }

.bootstrap-select .dropdown-item.active, .bootstrap-select .dropdown-item:active {
  color: #4d4d4f;
  background-color: rgba(77, 77, 79, 0.15); }

.bootstrap-select .dropdown-menu li.active small {
  color: #4d4d4f !important; }

.bootstrap-select .dropdown-menu {
  padding: 0;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1), 0 0.1px 0.1px rgba(0, 0, 0, 0.16); }
  .bootstrap-select .dropdown-menu li .dropdown-item {
    display: flex;
    align-content: center;
    color: #4d4d4f;
    border-top: 1px solid rgba(77, 77, 79, 0.15);
    line-height: 1.5;
    font-size: 0.875rem;
    padding: .5rem 1rem; }
  .bootstrap-select .dropdown-menu li:first-child .dropdown-item {
    border-top: 0; }

.bootstrap-select.dropdown.show {
  z-index: 9999 !important; }

.bootstrap-select + .fv-plugins-icon {
  right: 2rem; }

.custom-control-input {
  position: inherit;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 1; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #4470C3;
    background-color: #4470C3; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(68, 112, 195, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #a5bae2; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #cbd7ee;
    border-color: #cbd7ee; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

input[name="dates"],
input[name="date"] {
  border-right: 0; }

input[name="dates"] + .input-group-append .input-group-text,
input[name="date"] + .input-group-append .input-group-text {
  background-color: white;
  border-left: 0 !important; }

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 10px !important;
  padding-left: 10px !important; }

.btn-success {
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    background-color: #218838;
    border-color: #1e7e34; }

.file-btn-link {
  color: #009FDF;
  text-decoration: none;
  border-width: 0px;
  padding: 0 0 0 0;
  margin-bottom: 5px;
  background-color: transparent;
  text-align: left;
  width: 100% !important; }

.artwork-comment-field {
  height: 6.5rem; }

.inputfile {
  display: block;
  width: 100%; }

.btn-outline-primary {
  border: solid 2px !important; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: small; }

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: small; }

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: small; }

:-moz-placeholder {
  /* Firefox 18- */
  font-size: small; }

.table {
  background-color: rgba(255, 255, 255, 0.5) !important;
  color: black !important;
  margin-bottom: 0; }
  .table td,
  .table th {
    border-top: none;
    vertical-align: middle !important;
    padding: 0.35rem 1rem; }
    .table td input.custom-control-input, .table th input.custom-control-input {
      margin-top: 0.35rem; }
  .table thead tr th {
    border-top: 0;
    font-size: 0.875rem;
    font-weight: 600; }
    .table thead tr th a {
      font-weight: normal;
      text-decoration: underline;
      margin: 0 0.5rem; }
  .table tbody tr td {
    font-size: 0.875rem; }
  .table.simple-table thead tr th {
    border: 0;
    font-size: 0.875rem;
    font-weight: 600; }
  .table.simple-table tbody tr td {
    border: 0;
    font-size: 0.875rem; }

/* ==========================================================================
   Utilities
	========================================================================== */
/* Web Font Smooth */
html {
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: unset !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-smoothing: antialiased !important;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
  text-rendering: auto !important;
  text-rendering: optimizeSpeed !important;
  text-rendering: optimizeLegibility !important;
  text-rendering: geometricPrecision !important;
  text-rendering: inherit !important; }

/* Fading
   ========================================================================== */
.fade-in {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear; }

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear; }

/* Pagenation
   ========================================================================== */
.pagination-sm .page-link {
  font-weight: bold; }

/* AOS Vendor Annimation
	========================================================================== */
/* Remove Branding from Google Maps
	========================================================================== */
a[href^="http://maps.google.com/maps"] {
  display: none !important; }

a[href^="https://maps.google.com/maps"] {
  display: none !important; }

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none; }

.gmnoprint div {
  background: none !important; }

/* Embedded Video Container
	========================================================================== */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }
  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/* Customized Shadow
	========================================================================== */
.shadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0.1px 0.1px rgba(0, 0, 0, 0.16); }

/* Customized Width
	========================================================================== */
.container {
  max-width: 1440px !important; }

/* Customized Pagination
	========================================================================== */
.page-item.active .page-link {
  background-color: #009FDF !important;
  border: 0px !important;
  color: white !important; }

.page-link {
  border: 0px !important;
  color: #009FDF !important;
  padding: 0.5rem 1rem !important;
  background-color: transparent; }

/* Inserting a collapsed row between two flex items will make 
 * the flex item that comes after it break to a new row */
.break {
  flex-basis: 100%;
  height: 0; }

/* Use a collapsed column to break to a new column */
.break-column {
  flex-basis: 100%;
  width: 0; }

.alert {
  font-size: 0.875rem;
  text-align: center;
  align-self: center;
  width: auto;
  position: fixed;
  z-index: 9999999;
  top: 4rem;
  padding: 1.1rem; }

.alert-message-success {
  color: white;
  background-color: #60af75; }

.alert-message-error {
  color: white;
  background-color: #DC3545; }

ul.nav-tabs {
  border: none !important; }
  ul.nav-tabs .nav-item .nav-link {
    font-weight: 500;
    font-size: 0.875rem;
    border: none;
    color: #4d4d4f;
    padding: 0.5rem 0;
    margin: 0 1rem 0 0;
    cursor: pointer; }
    ul.nav-tabs .nav-item .nav-link.active {
      font-weight: 600;
      color: #00aeef;
      border-bottom: 4px solid #009FDF; }
  ul.nav-tabs .nav-item .nav-label {
    display: block;
    font-weight: 600;
    font-size: 0.875rem;
    padding: 0.5rem 0;
    margin: 0 1rem 0 0; }

.page-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline; }

.products-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .products-list > li {
    margin-bottom: 1.5rem; }
    .products-list > li.needs-action {
      border: 2px solid #DC3545;
      background-color: #DC3545;
      border-radius: 0.25rem; }
      .products-list > li.needs-action .message {
        color: white;
        font-weight: 600;
        font-size: 0.875rem;
        padding: 0.5rem 1.5rem; }
    .products-list > li.action-taken {
      border: 2px solid #009FDF;
      background-color: #009FDF;
      border-radius: 0.25rem; }
      .products-list > li.action-taken .message {
        color: white;
        font-weight: 600;
        font-size: 0.875rem;
        padding: 0.5rem 1.5rem; }
    .products-list > li.positive-action {
      border: 2px solid #60af75;
      background-color: #60af75;
      border-radius: 0.25rem; }
      .products-list > li.positive-action .message {
        color: white;
        font-weight: 600;
        font-size: 0.875rem;
        padding: 0.5rem 1.5rem; }
    .products-list > li.neutral-needs-action {
      border: 2px solid #dac302;
      background-color: #dac302;
      border-radius: 0.25rem; }
      .products-list > li.neutral-needs-action .message {
        color: white;
        font-weight: 600;
        font-size: 0.875rem;
        padding: 0.5rem 1.5rem; }

.product-item {
  flex-direction: column; }
  .product-item .general-details {
    display: flex;
    flex-direction: row; }
    .product-item .general-details .product-img {
      padding: 1.5rem;
      min-width: 216px;
      border-right: 1px solid rgba(0, 0, 0, 0.125); }
      .product-item .general-details .product-img img {
        max-width: 167px;
        max-height: 9rem;
        display: block;
        margin: 0 auto; }
      .product-item .general-details .product-img ul {
        list-style-type: none;
        margin: 0;
        padding: 0; }
        .product-item .general-details .product-img ul li .label {
          font-size: 0.875rem;
          font-weight: 600; }
    .product-item .general-details .label {
      font-size: 0.875rem;
      font-weight: 600; }
      .product-item .general-details .label.product-attribute {
        font-weight: 500;
        padding-bottom: 0.25rem; }
    .product-item .general-details .product-attribute-group {
      margin-bottom: 1rem; }
    .product-item .general-details .product-info {
      overflow: auto;
      padding: 1.5rem;
      flex: 1 1 auto !important;
      width: 100%; }
      .product-item .general-details .product-info .fixed-info {
        display: flex;
        flex-direction: row; }
        .product-item .general-details .product-info .fixed-info ul {
          list-style: none;
          margin: 0 0 1rem 0;
          padding: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap; }
          .product-item .general-details .product-info .fixed-info ul li {
            margin-bottom: 0.75rem;
            padding-right: 1rem; }
            .product-item .general-details .product-info .fixed-info ul li .label {
              font-size: 0.875rem;
              font-weight: 600; }
            .product-item .general-details .product-info .fixed-info ul li.stack {
              margin-bottom: 0rem; }
          .product-item .general-details .product-info .fixed-info ul:first-child {
            margin-left: 1rem;
            width: 75%; }
            .product-item .general-details .product-info .fixed-info ul:first-child li {
              width: 25%; }
          .product-item .general-details .product-info .fixed-info ul:last-child {
            width: 25%;
            justify-content: flex-end;
            text-align: right; }
          .product-item .general-details .product-info .fixed-info ul.unit-price-cost {
            align-content: baseline; }
      .product-item .general-details .product-info .required-attributes form {
        margin: 0 0.5rem; }
      .product-item .general-details .product-info .required-attributes .table {
        margin-bottom: 0; }
        .product-item .general-details .product-info .required-attributes .table thead tr th {
          border-top: 0;
          font-size: 0.875rem;
          font-weight: 500; }
        .product-item .general-details .product-info .required-attributes .table tbody tr td {
          font-size: 0.875rem; }
      .product-item .general-details .product-info .required-attributes .simple-table {
        width: 315px; }
      .product-item .general-details .product-info h5.total {
        margin-top: 3rem;
        margin-bottom: 0;
        text-align: right;
        font-size: 1.125rem;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        .product-item .general-details .product-info h5.total span {
          font-weight: 600;
          margin-left: 0.25rem; }
        .product-item .general-details .product-info h5.total div.cost-and-price {
          margin-left: 1rem; }
  .product-item .care-instructions {
    width: 100%;
    padding: 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .product-item .country-of-origin {
    width: 100%;
    padding: 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
    .product-item .country-of-origin h2 {
      margin-bottom: 1rem; }
  .product-item .special-instructions {
    width: 100%;
    padding: 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
    .product-item .special-instructions .form-group {
      margin-bottom: 0; }
    .product-item .special-instructions ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .product-item .special-instructions ul li {
        margin-bottom: 0.5rem; }
        .product-item .special-instructions ul li a {
          display: flex;
          align-items: center;
          margin: 0 0.5rem;
          line-height: 1;
          font-weight: 600;
          text-decoration: none; }
          .product-item .special-instructions ul li a.remove {
            color: #DC3545; }
            .product-item .special-instructions ul li a.remove:hover {
              color: #ba2d3a; }
          .product-item .special-instructions ul li a i {
            margin-right: 0.5rem; }
          .product-item .special-instructions ul li a.add {
            color: #009FDF;
            margin-left: 0.15rem !important; }
            .product-item .special-instructions ul li a.add:hover {
              color: #00b6ff; }
            .product-item .special-instructions ul li a.add.add-for-instruction {
              margin-left: 0.5rem !important; }
  .product-item .fabric-composition {
    width: 100%;
    padding: 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
    .product-item .fabric-composition .form-group {
      margin-bottom: 0; }
    .product-item .fabric-composition #fibreContents ul,
    .product-item .fabric-composition #garmentComponents ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .product-item .fabric-composition #fibreContents ul li,
      .product-item .fabric-composition #garmentComponents ul li {
        margin-bottom: 0.5rem; }
    .product-item .fabric-composition #garmentComponents li.garment {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      padding: 0.5rem 0; }
    .product-item .fabric-composition .material-actions {
      display: flex;
      height: 100%; }
      .product-item .fabric-composition .material-actions a {
        display: flex;
        align-items: center;
        margin: 0 0.5rem;
        line-height: 1;
        font-weight: 600;
        text-decoration: none; }
        .product-item .fabric-composition .material-actions a.remove {
          color: #DC3545; }
          .product-item .fabric-composition .material-actions a.remove:hover {
            color: #ba2d3a; }
        .product-item .fabric-composition .material-actions a i {
          margin-right: 0.5rem; }
        .product-item .fabric-composition .material-actions a.add {
          color: #009FDF;
          margin-left: 0.15rem !important; }
          .product-item .fabric-composition .material-actions a.add:hover {
            color: #00b6ff; }
          .product-item .fabric-composition .material-actions a.add.add-for-garment {
            margin-left: 0.28rem !important; }
    .product-item .fabric-composition .caution-maintenance {
      margin-top: 2rem;
      padding: 1rem 1rem 1.5rem 1rem;
      background-color: #f1f1f1;
      border-radius: 0.2rem; }
  .product-item .quantity {
    width: 100%;
    padding: 1.5rem 0.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
    .product-item .quantity .simple-table {
      width: 315px; }
  .product-item.standard .product-info .fixed-info ul li.description, .product-item.care-label .product-info .fixed-info ul li.description {
    flex: 1 1 auto !important; }
  .product-item.standard .product-info .required-attributes .simple-table, .product-item.care-label .product-info .required-attributes .simple-table {
    width: 315px; }

#orderList .order-item {
  padding: 0rem; }
  #orderList .order-item .order-details {
    flex: 1 1 auto !important;
    padding: 1.5rem 0rem 0.25rem 1.5rem; }
    #orderList .order-item .order-details h5.company {
      font-size: 1rem;
      font-weight: 600; }
    #orderList .order-item .order-details .order-meta {
      display: flex;
      flex-direction: row;
      width: 100%; }
      #orderList .order-item .order-details .order-meta ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; }
        #orderList .order-item .order-details .order-meta ul li {
          margin-bottom: 0.75rem; }
          #orderList .order-item .order-details .order-meta ul li .label {
            font-size: 0.875rem;
            font-weight: 600;
            padding-right: 4rem; }
            #orderList .order-item .order-details .order-meta ul li .label.order-detail-header {
              padding-right: 0rem; }
            #orderList .order-item .order-details .order-meta ul li .label.short-label {
              padding-right: 5.8rem !important; }
          #orderList .order-item .order-details .order-meta ul li .form-control {
            margin-top: 0.2rem; }
          #orderList .order-item .order-details .order-meta ul li input.form-control {
            margin-top: 0 !important; }
      #orderList .order-item .order-details .order-meta .status-icons span {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        margin-right: 0.25rem;
        margin-top: 0.2rem; }
        #orderList .order-item .order-details .order-meta .status-icons span.placed.active {
          background-color: #ab3511; }
        #orderList .order-item .order-details .order-meta .status-icons span.art-prepared.active {
          background-color: #e27938; }
        #orderList .order-item .order-details .order-meta .status-icons span.art-approved.active {
          background-color: #e1d35f; }
        #orderList .order-item .order-details .order-meta .status-icons span.production.active {
          background-color: #0f6c91; }
        #orderList .order-item .order-details .order-meta .status-icons span.finance.active {
          background-color: #918a07; }
        #orderList .order-item .order-details .order-meta .status-icons span.shipped.active {
          background-color: #8a12bf; }
  #orderList .order-item .order-price {
    padding: 1.5rem;
    background-color: #4d4d4f;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 600;
    width: 220px;
    text-align: right;
    flex-wrap: wrap; }
    #orderList .order-item .order-price .descriptor {
      font-size: 0.975rem;
      margin-right: 0.25rem; }
    #orderList .order-item .order-price .currency {
      font-size: 0.875rem;
      margin-right: 0.25rem; }
    #orderList .order-item .order-price .value {
      font-size: 1.125rem; }
    #orderList .order-item .order-price .break {
      flex-basis: 100%;
      height: 0; }

#userList .user-item {
  padding: 0rem; }
  #userList .user-item .user-details {
    flex: 1 1 auto !important;
    padding: 0.25rem 1rem 0.25rem 1rem; }
    #userList .user-item .user-details .user-meta {
      display: flex;
      flex-direction: row;
      width: 100%; }
      #userList .user-item .user-details .user-meta ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; }
        #userList .user-item .user-details .user-meta ul li {
          margin: 0.75rem 1.5rem 0.75rem 1.5rem;
          width: 10rem; }
          #userList .user-item .user-details .user-meta ul li .label {
            font-size: 0.875rem;
            font-weight: 600;
            padding-right: 4rem; }
            #userList .user-item .user-details .user-meta ul li .label.order-detail-header {
              padding-right: 0rem; }
            #userList .user-item .user-details .user-meta ul li .label.short-label {
              padding-right: 5.8rem !important; }
          #userList .user-item .user-details .user-meta ul li .form-control {
            margin-top: 0.2rem; }
          #userList .user-item .user-details .user-meta ul li input.form-control {
            margin-top: 0 !important; }

#customerList .customer-item {
  padding: 0rem; }
  #customerList .customer-item .customer-details {
    flex: 1 1 auto !important;
    padding: 0.25rem 1rem 0.25rem 1rem; }
    #customerList .customer-item .customer-details .customer-meta {
      display: flex;
      flex-direction: row;
      width: 100%; }
      #customerList .customer-item .customer-details .customer-meta ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; }
        #customerList .customer-item .customer-details .customer-meta ul li {
          margin: 0.75rem 1.5rem 0.75rem 1.5rem;
          width: 10rem; }
          #customerList .customer-item .customer-details .customer-meta ul li .label {
            font-size: 0.875rem;
            font-weight: 600;
            padding-right: 4rem; }
            #customerList .customer-item .customer-details .customer-meta ul li .label.order-detail-header {
              padding-right: 0rem; }
            #customerList .customer-item .customer-details .customer-meta ul li .label.short-label {
              padding-right: 5.8rem !important; }
          #customerList .customer-item .customer-details .customer-meta ul li .form-control {
            margin-top: 0.2rem; }
          #customerList .customer-item .customer-details .customer-meta ul li input.form-control {
            margin-top: 0 !important; }

#vendorList .vendor-item {
  padding: 0rem; }
  #vendorList .vendor-item .vendor-details {
    flex: 1 1 auto !important;
    padding: 0.25rem 1rem 0.25rem 1rem; }
    #vendorList .vendor-item .vendor-details .vendor-meta {
      display: flex;
      flex-direction: row;
      width: 100%; }
      #vendorList .vendor-item .vendor-details .vendor-meta ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; }
        #vendorList .vendor-item .vendor-details .vendor-meta ul li {
          margin: 0.75rem 1.5rem 0.75rem 1.5rem;
          width: 10rem; }
          #vendorList .vendor-item .vendor-details .vendor-meta ul li .label {
            font-size: 0.875rem;
            font-weight: 600;
            padding-right: 4rem; }
            #vendorList .vendor-item .vendor-details .vendor-meta ul li .label.order-detail-header {
              padding-right: 0rem; }
            #vendorList .vendor-item .vendor-details .vendor-meta ul li .label.short-label {
              padding-right: 5.8rem !important; }
          #vendorList .vendor-item .vendor-details .vendor-meta ul li .form-control {
            margin-top: 0.2rem; }
          #vendorList .vendor-item .vendor-details .vendor-meta ul li input.form-control {
            margin-top: 0 !important; }

.overlay-message {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 99999999; }
  .overlay-message .overlay-message-text {
    position: absolute;
    top: 5.5rem;
    left: 50%;
    font-size: 1.75rem;
    font-weight: 600;
    color: #00aeef;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); }

.input-not-visible {
  width: 0;
  height: 0;
  border-width: 0;
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0); }

#site-navigation {
  z-index: 99999;
  background-color: white;
  padding: 0;
  padding-right: 0.5rem;
  padding-left: 1rem;
  height: 86px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
  @media (max-width: 767.98px) {
    #site-navigation {
      width: 100vw !important; } }
  #site-navigation .container-fluid, #site-navigation .container-sm, #site-navigation .container-md, #site-navigation .container-lg, #site-navigation .container-xl {
    height: 100%; }
  #site-navigation .navbar-brand {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1; }
    @media (min-width: 576px) {
      #site-navigation .navbar-brand {
        padding-left: 1rem; } }
    #site-navigation .navbar-brand .svg {
      width: 160px; }
  #site-navigation .navbar-text.page-title {
    font-family: 'Whitney', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    color: black;
    padding-left: 1rem;
    margin-bottom: 0.3rem;
    margin-left: 0rem;
    margin-right: 0rem;
    position: relative;
    flex-shrink: 1; }
    @media (max-width: 1199.98px) {
      #site-navigation .navbar-text.page-title {
        font-size: 1.25rem; } }
  #site-navigation .navbar-text {
    color: white;
    font-size: .875rem;
    line-height: 1.25rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    padding-top: 0.65rem;
    flex-shrink: 0; }
  #site-navigation .navbar-collapse {
    height: 100%; }
  #site-navigation .navbar-nav {
    height: 100%;
    flex-wrap: nowrap; }
    #site-navigation .navbar-nav .nav-item {
      height: 100%;
      display: flex;
      align-items: center;
      border-bottom: 4px solid transparent;
      margin-left: 0.925rem;
      margin-right: 0.925rem;
      padding-top: 0.5rem;
      font-size: .875rem;
      font-weight: 500;
      line-height: 1.25rem;
      flex-shrink: 0; }
      #site-navigation .navbar-nav .nav-item .dropdown-item {
        background-color: white; }
        #site-navigation .navbar-nav .nav-item .dropdown-item.active .in-dropdown {
          color: #009FDF !important; }
      #site-navigation .navbar-nav .nav-item .nav-link {
        font-size: .875rem;
        font-weight: 600;
        line-height: 4.5rem;
        color: black;
        padding: 0; }
        #site-navigation .navbar-nav .nav-item .nav-link.in-dropdown {
          line-height: 3rem;
          color: black !important; }
        #site-navigation .navbar-nav .nav-item .nav-link.noPendingOrder {
          color: #dee2e6;
          pointer-events: none; }
        #site-navigation .navbar-nav .nav-item .nav-link.yesPendingOrder {
          color: black; }
        #site-navigation .navbar-nav .nav-item .nav-link.account {
          text-transform: none; }
        #site-navigation .navbar-nav .nav-item .nav-link.sign-out, #site-navigation .navbar-nav .nav-item .nav-link.log-in {
          font-weight: 700; }
        #site-navigation .navbar-nav .nav-item .nav-link#cartLink {
          font-size: 1.5rem;
          position: relative; }
          #site-navigation .navbar-nav .nav-item .nav-link#cartLink .item-count {
            position: absolute;
            top: 10px;
            right: -15px;
            display: none;
            min-width: 24px;
            height: 24px;
            border-radius: 12px;
            padding: 0px 6px;
            background-color: red;
            border: 2px solid white;
            text-align: center;
            font-size: 0.75rem; }
            #site-navigation .navbar-nav .nav-item .nav-link#cartLink .item-count:not([data-items="0"]) {
              display: block; }
      #site-navigation .navbar-nav .nav-item.active {
        border-color: #009FDF; }
        #site-navigation .navbar-nav .nav-item.active .nav-link {
          color: #009FDF; }

.site-footer {
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.75rem;
  padding: 2rem 0 2rem 0; }
  .site-footer p {
    font-size: 0.85rem; }
  .site-footer a {
    font-size: 0.85rem;
    font-weight: normal;
    color: black; }
    .site-footer a.footer-link {
      display: inline-block;
      padding: 1rem 0.5rem;
      margin-bottom: 1rem;
      font-size: 1rem; }

#page.playground form.md .form-row {
  margin-left: -15px;
  margin-right: -15px; }
  #page.playground form.md .form-row .form-group {
    padding: 15px 15px 0;
    align-self: flex-end; }
    #page.playground form.md .form-row .form-group .input-group-prepend {
      left: 0.75rem; }
    #page.playground form.md .form-row .form-group .input-group-append {
      right: 0.75rem; }

#page.playground form.md .form-group {
  position: relative;
  padding-top: 15px;
  margin-bottom: 15px; }
  #page.playground form.md .form-group.has-danger {
    margin-bottom: 2.5rem; }
    #page.playground form.md .form-group.has-danger .line {
      bottom: 0rem; }
    #page.playground form.md .form-group.has-danger .fv-help-block {
      margin-top: 0; }
    #page.playground form.md .form-group.has-danger .fv-plugins-message-container {
      position: absolute;
      top: 60px;
      z-index: 4; }
    #page.playground form.md .form-group.has-danger .form-text + .fv-plugins-message-container {
      top: 80px; }

#page.playground form.md.search-form .form-group:after {
  position: absolute;
  font-size: 0.75rem;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f002";
  right: 0;
  top: 43%; }

#page.playground form.md .iti {
  border: 0;
  border-radius: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  z-index: 2;
  font-size: 1.125rem;
  color: #00aeef;
  width: 100%;
  display: inline-flex; }
  #page.playground form.md .iti .iti__flag-container {
    position: relative; }
  #page.playground form.md .iti + label {
    position: absolute;
    z-index: 1;
    font-size: .75rem;
    font-weight: 600;
    top: 0;
    margin: 0;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
    left: 15px;
    right: 25px; }

#page.playground form.md .form-control {
  border: 0;
  border-radius: 0;
  padding: 10px 0;
  background-color: transparent;
  position: relative;
  z-index: 2;
  font-size: 1.125rem;
  color: #00aeef; }
  #page.playground form.md .form-control:not(textarea) {
    height: 44px; }
  #page.playground form.md .form-control + .fv-plugins-icon + label,
  #page.playground form.md .form-control + label {
    position: absolute;
    z-index: 1;
    top: 25px;
    margin: 0;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
    left: 0;
    right: 10px;
    color: #6c757d; }
  #page.playground form.md .form-control:focus, #page.playground form.md .form-control:active {
    box-shadow: none;
    background-color: transparent; }
  #page.playground form.md .form-control::-webkit-input-placeholder {
    color: #999; }
  #page.playground form.md .form-control::placeholder {
    color: #999; }
  #page.playground form.md .form-control:focus + .fv-plugins-icon + label,
  #page.playground form.md .form-control:focus + label, #page.playground form.md .form-control:active + .fv-plugins-icon + label,
  #page.playground form.md .form-control:active + label, #page.playground form.md .form-control.has-value + .fv-plugins-icon + label,
  #page.playground form.md .form-control.has-value + label {
    font-size: 0.75rem !important;
    font-weight: 600 !important;
    top: 0 !important; }
  #page.playground form.md .form-control:disabled {
    cursor: not-allowed; }
    #page.playground form.md .form-control:disabled + .fv-plugins-icon + label,
    #page.playground form.md .form-control:disabled + label {
      color: #6c757d;
      opacity: 0.65; }
      #page.playground form.md .form-control:disabled + .fv-plugins-icon + label + .line,
      #page.playground form.md .form-control:disabled + label + .line {
        opacity: 0.65;
        background-color: #6c757d; }
        #page.playground form.md .form-control:disabled + .fv-plugins-icon + label + .line:after,
        #page.playground form.md .form-control:disabled + label + .line:after {
          background: #6c757d; }
  #page.playground form.md .form-control.has-value:disabled + .fv-plugins-icon + label,
  #page.playground form.md .form-control.has-value:disabled + label {
    font-size: .75rem;
    font-weight: 600;
    top: 0; }

#page.playground form.md .form-control.is-valid,
#page.playground form.md .was-validated .form-control:valid {
  background-image: none !important; }

#page.playground form.md .form-control.is-invalid,
#page.playground form.md .was-validated .form-control:invalid {
  background-image: none !important; }
  #page.playground form.md .form-control.is-invalid + .fv-plugins-icon + label,
  #page.playground form.md .form-control.is-invalid + label,
  #page.playground form.md .was-validated .form-control:invalid + .fv-plugins-icon + label,
  #page.playground form.md .was-validated .form-control:invalid + label {
    color: #DC3545; }
    #page.playground form.md .form-control.is-invalid + .fv-plugins-icon + label + .line,
    #page.playground form.md .form-control.is-invalid + label + .line,
    #page.playground form.md .was-validated .form-control:invalid + .fv-plugins-icon + label + .line,
    #page.playground form.md .was-validated .form-control:invalid + label + .line {
      background-color: #DC3545; }
      #page.playground form.md .form-control.is-invalid + .fv-plugins-icon + label + .line:after,
      #page.playground form.md .form-control.is-invalid + label + .line:after,
      #page.playground form.md .was-validated .form-control:invalid + .fv-plugins-icon + label + .line:after,
      #page.playground form.md .was-validated .form-control:invalid + label + .line:after {
        background: #DC3545; }

#page.playground form.md label.required:after {
  content: " *";
  color: #DC3545;
  display: inline-block;
  margin-left: 0.15rem; }

#page.playground form.md .form-text {
  position: absolute;
  bottom: -1.25rem;
  margin: 0;
  font-size: .75rem; }

#page.playground form.md .input-group-append {
  position: absolute;
  z-index: 3;
  top: 1.25rem;
  right: 0;
  border: 0;
  border-left: 0;
  border-radius: 0;
  background-color: transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    #page.playground form.md .input-group-append {
      transition: none; } }
  #page.playground form.md .input-group-append .input-group-text {
    border: 0;
    padding: .125rem 0rem .125rem 1rem;
    font-size: 1.5rem;
    line-height: 1.714;
    height: 43px;
    background-color: transparent; }
    #page.playground form.md .input-group-append .input-group-text .icon-search:before {
      color: #6c757d; }
    #page.playground form.md .input-group-append .input-group-text .icon-calendar:before {
      color: #6c757d; }
  #page.playground form.md .input-group-append .btn {
    border: 0;
    padding: .125rem 0rem .125rem 1rem;
    font-size: 1.5rem; }
    #page.playground form.md .input-group-append .btn .icon-search:before,
    #page.playground form.md .input-group-append .btn .icon-calendar:before {
      color: #DC3545; }
    #page.playground form.md .input-group-append .btn:hover {
      border: 0;
      background-color: transparent;
      color: #00aeef; }
      #page.playground form.md .input-group-append .btn:hover .icon-search:before,
      #page.playground form.md .input-group-append .btn:hover .icon-calendar:before {
        color: #00aeef; }
    #page.playground form.md .input-group-append .btn:focus, #page.playground form.md .input-group-append .btn:active {
      box-shadow: none; }

#page.playground form.md .input-group-prepend {
  position: absolute;
  z-index: 3;
  top: 1.25rem;
  left: 0;
  border: 0;
  border-left: 0;
  border-radius: 0;
  background-color: transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    #page.playground form.md .input-group-prepend {
      transition: none; } }
  #page.playground form.md .input-group-prepend .input-group-text {
    border: 0;
    padding: .125rem 1rem .125rem 0rem;
    font-size: 1.5rem;
    line-height: 1.714;
    height: 43px;
    background-color: transparent; }
    #page.playground form.md .input-group-prepend .input-group-text .icon-search:before {
      color: #00aeef; }
    #page.playground form.md .input-group-prepend .input-group-text .icon-calendar:before {
      color: #DC3545; }
  #page.playground form.md .input-group-prepend .btn {
    border: 0;
    padding: .125rem 1rem .125rem 0rem;
    font-size: 1.5rem; }
    #page.playground form.md .input-group-prepend .btn .icon-search:before,
    #page.playground form.md .input-group-prepend .btn .icon-calendar:before {
      color: #DC3545; }
    #page.playground form.md .input-group-prepend .btn:hover {
      border: 0;
      background-color: transparent;
      color: #00aeef; }
      #page.playground form.md .input-group-prepend .btn:hover .icon-search:before,
      #page.playground form.md .input-group-prepend .btn:hover .icon-calendar:before {
        color: #00aeef; }
    #page.playground form.md .input-group-prepend .btn:focus, #page.playground form.md .input-group-prepend .btn:active {
      box-shadow: none; }
  #page.playground form.md .input-group-prepend + .form-control {
    padding-left: 2rem; }
    #page.playground form.md .input-group-prepend + .form-control + label {
      padding-left: 2rem; }
    #page.playground form.md .input-group-prepend + .form-control:focus + label {
      padding-left: 0; }
    #page.playground form.md .input-group-prepend + .form-control.has-value + label {
      padding-left: 0rem; }

#page.playground form.md .form-row .form-control + label {
  left: 15px;
  right: 25px; }

#page.playground form.md .form-row .line {
  left: 15px;
  right: 15px; }

#page.playground form.md .line {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #00aeef; }
  #page.playground form.md .line:after {
    content: "";
    height: 1px;
    position: absolute;
    display: block;
    background: #00aeef;
    left: 0;
    right: 0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0; }

#page.playground form.md .form-control:focus ~ .line:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1); }

#page.playground form.md select.form-control:not([size]):not([multiple]) {
  height: 44px; }

#page.playground form.md .form-group.has-danger .line {
  bottom: 20px; }

#page.playground form.md .bootstrap-select > .dropdown-toggle {
  background: transparent;
  border: 0;
  border: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  position: relative;
  z-index: 2;
  font-size: 1.125rem;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 400;
  color: #00aeef; }
  #page.playground form.md .bootstrap-select > .dropdown-toggle:active, #page.playground form.md .bootstrap-select > .dropdown-toggle:focus {
    box-shadow: none !important;
    outline: 0 !important; }
  #page.playground form.md .bootstrap-select > .dropdown-toggle:after {
    font-family: 'icomoon' !important;
    content: "\e905";
    speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #DC3545;
    font-size: 1.5em;
    width: 1.5rem;
    height: 1.5rem;
    border: 0; }
  #page.playground form.md .bootstrap-select > .dropdown-toggle.bs-placeholder, #page.playground form.md .bootstrap-select > .dropdown-toggle.dropdown-toggle.bs-placeholder:active, #page.playground form.md .bootstrap-select > .dropdown-toggle.dropdown-toggle.bs-placeholder:focus, #page.playground form.md .bootstrap-select > .dropdown-toggle.dropdown-toggle.bs-placeholder:hover {
    color: #6c757d; }

#page.playground form.md .bootstrap-select.disabled > .dropdown-toggle {
  cursor: not-allowed; }
  #page.playground form.md .bootstrap-select.disabled > .dropdown-toggle:after {
    color: #6c757d;
    opacity: 0.65; }

#page.playground form.md .bootstrap-select.disabled + label {
  color: #6c757d;
  opacity: 0.65; }
  #page.playground form.md .bootstrap-select.disabled + label + .line {
    opacity: 0.65;
    background-color: #6c757d; }
    #page.playground form.md .bootstrap-select.disabled + label + .line:after {
      background: #6c757d; }

#page.playground form.md .bootstrap-select.show + label {
  font-size: .75rem;
  font-weight: 600;
  top: 0; }

#page.playground form.md .bootstrap-select.show > .btn-light.dropdown-toggle {
  background: transparent;
  border: 0;
  box-shadow: none !important;
  outline: 0 !important; }
  #page.playground form.md .bootstrap-select.show > .btn-light.dropdown-toggle:active, #page.playground form.md .bootstrap-select.show > .btn-light.dropdown-toggle:focus {
    box-shadow: none !important;
    outline: 0 !important; }

#page.playground form.md .bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0; }

#page.playground form.md .bootstrap-select .bs-ok-default:after {
  font-family: 'icomoon' !important;
  content: "\e90d";
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #00aeef;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  border: 0;
  width: 1.5rem;
  height: 1.5rem; }

#page.playground form.md .bootstrap-select .dropdown-item.selected {
  color: #00aeef !important;
  background-color: rgba(0, 174, 239, 0.15); }

#page.playground form.md .bootstrap-select .dropdown-item.active, #page.playground form.md .bootstrap-select .dropdown-item:active {
  color: #00aeef;
  background-color: rgba(0, 174, 239, 0.15); }

#page.playground form.md .bootstrap-select .dropdown-menu li.active small {
  color: #00aeef !important; }

#page.playground form.md .bootstrap-select .dropdown-menu {
  padding: 0; }
  #page.playground form.md .bootstrap-select .dropdown-menu li .dropdown-item {
    color: #6c757d;
    border-top: 1px solid rgba(0, 174, 239, 0.15); }
  #page.playground form.md .bootstrap-select .dropdown-menu li:first-child .dropdown-item {
    border-top: 0; }

#page.playground form.md .bootstrap-select.dropdown.show {
  z-index: 9999 !important; }

#page.playground form.md .bootstrap-select + label {
  font-size: .75rem;
  font-weight: 600;
  top: 0 !important;
  transition: none !important; }

#page.playground form.md .bs-searchbox .form-control {
  border-bottom: 1px solid #00aeef !important; }

#page.playground .no-js form.md .form-control + label {
  font-size: 0.75rem;
  top: 0; }

#page.playground .form-check {
  padding: 0; }
  #page.playground .form-check input[type=checkbox] {
    visibility: hidden !important; }
    #page.playground .form-check input[type=checkbox] + label {
      text-transform: none;
      position: relative;
      padding-left: 2rem;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.5rem;
      display: flex;
      align-items: center; }
      #page.playground .form-check input[type=checkbox] + label:before {
        position: absolute;
        left: 0;
        font-family: 'icomoon';
        font-size: 1.5rem;
        color: #00aeef;
        content: "\e902"; }
    #page.playground .form-check input[type=checkbox]:disabled + label {
      font-weight: 500; }
      #page.playground .form-check input[type=checkbox]:disabled + label:before {
        color: #6c757d !important; }
    #page.playground .form-check input[type=checkbox]:checked + label:before {
      content: "\e901"; }
  #page.playground .form-check input[type=radio] {
    visibility: hidden !important; }
    #page.playground .form-check input[type=radio] + label {
      text-transform: none;
      position: relative;
      padding-left: 2rem;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.35rem;
      display: flex;
      align-items: center; }
      #page.playground .form-check input[type=radio] + label:before {
        position: absolute;
        left: 0;
        font-family: 'icomoon';
        font-size: 1.5rem;
        color: #00aeef;
        content: "\e907"; }
    #page.playground .form-check input[type=radio]:disabled + label {
      font-weight: 500; }
      #page.playground .form-check input[type=radio]:disabled + label:before {
        color: #6c757d !important; }
    #page.playground .form-check input[type=radio]:checked + label:before {
      content: "\e903"; }

#page.playground .fv-plugins-icon {
  top: 1.25rem !important; }
  #page.playground .fv-plugins-icon.fa {
    font-weight: 300 !important; }
  #page.playground .fv-plugins-icon:before {
    font-size: 2rem; }

#page.playground .ui-widget {
  font-family: "Montserrat", sans-serif; }

#page.playground .ui-widget-header {
  color: #00aeef;
  font-weight: 600;
  border: 0;
  background: white; }

#page.playground .ui-widget-content {
  color: #00aeef; }

#page.playground .ui-datepicker {
  z-index: 1060 !important; }

#page.playground .ui-datepicker th {
  font-weight: 500; }

#page.playground .ui-state-default, #page.playground .ui-widget-content .ui-state-default, #page.playground .ui-widget-header .ui-state-default, #page.playground .ui-button, #page.playground html .ui-button.ui-state-disabled:hover, #page.playground html .ui-button.ui-state-disabled:active {
  color: #00aeef;
  background: white;
  border-color: white; }

#page.playground .ui-state-active, #page.playground .ui-widget-content .ui-state-active, #page.playground .ui-widget-header .ui-state-active, #page.playground a.ui-button:active, #page.playground .ui-button:active, #page.playground .ui-button.ui-state-active:hover {
  background-color: #DC3545;
  color: white; }

#page.playground .ui-state-highlight, #page.playground .ui-widget-content .ui-state-highlight, #page.playground .ui-widget-header .ui-state-highlight {
  background: #e9ecef; }

#page.playground .iti__flag {
  background-image: url(/static/media/flags.416250f6.png); }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  #page.playground .iti__flag {
    background-image: url(/static/media/flags@2x.d429a577.png); } }

body .bodyWrapLogin {
  background: url(/static/media/bg-1.88f46178.jpg) left top no-repeat, url(/static/media/bg-2.c57092d4.jpg) right bottom no-repeat;
  background-attachment: fixed; }

.bodyWrap {
  background: url(/static/media/bg-1-mirror.211414d8.jpg) right top no-repeat;
  background-attachment: fixed; }

.hanger {
  height: 20vh;
  width: 40px;
  top: 0;
  background: url(/static/media/rope.3cdb316c.png) right bottom no-repeat;
  z-index: 9999;
  margin: 0 auto;
  position: relative; }

#page.login section.site-content {
  display: flex;
  justify-content: center; }
  #page.login section.site-content section.content-body {
    height: 100%;
    width: 100%; }
    #page.login section.site-content section.content-body .login-info {
      font-size: 0.85rem; }
  #page.login section.site-content .loginWrap {
    width: 33.33%;
    margin: 0 auto;
    border-radius: 2%;
    overflow: hidden;
    position: relative;
    top: -45px; }
    #page.login section.site-content .loginWrap .loginlogo {
      background-color: #009FDF;
      display: block;
      padding: 2rem 4rem;
      top: 0;
      color: white;
      text-transform: uppercase;
      text-align: center; }
      #page.login section.site-content .loginWrap .loginlogo h6 {
        font-weight: bold;
        letter-spacing: .1rem; }
      #page.login section.site-content .loginWrap .loginlogo span.circle {
        box-shadow: inset 3px 3px 8px 0px rgba(0, 0, 0, 0.4);
        height: 30px;
        width: 30px;
        background-color: white;
        border-radius: 50%;
        display: block; }
    #page.login section.site-content .loginWrap .login-form {
      overflow: hidden;
      padding: 4rem 4rem;
      background-color: white; }
    #page.login section.site-content .loginWrap .site-footer {
      padding: 2rem 0 2rem 0; }

body .bodyWrapResetPassword {
  background: url(/static/media/bg-1.88f46178.jpg) left top no-repeat, url(/static/media/bg-2.c57092d4.jpg) right bottom no-repeat;
  background-attachment: fixed; }

#page.reset section.site-content {
  display: flex;
  justify-content: center;
  align-items: center; }
  #page.reset section.site-content section.content-body {
    width: 100%; }
  #page.reset section.site-content .resetWrap {
    width: 33.33%;
    margin: 0 auto;
    border-radius: 2%;
    overflow: hidden;
    position: relative;
    top: -45px; }
    #page.reset section.site-content .resetWrap .reset-form {
      overflow: hidden;
      padding: 4rem 4rem;
      background-color: white; }
    #page.reset section.site-content .resetWrap .site-footer {
      padding: 2rem 0 2rem 0; }

a.toggleBTN {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #009FDF;
  padding: 10px 30px 10px 15px;
  line-height: 40px;
  top: 0;
  left: 0;
  background-color: #f1f1f1; }
  a.toggleBTN #ToggleIcon {
    margin-right: 0.5rem;
    font-size: 0.875rem; }
  a.toggleBTN #ToggleIcon:before {
    font-family: 'Font Awesome 5 Pro';
    content: '\f068'; }
  a.toggleBTN.collapsed #ToggleIcon:before {
    content: '\f067'; }

.product-list .searchForm {
  background-color: #f1f1f1;
  padding: 35px;
  position: relative; }
  .product-list .searchForm h5.filterTitle {
    font-size: .875rem;
    background-color: #f1f1f1;
    position: relative;
    padding: 0px 10px 0px 0px;
    left: -5px;
    z-index: 999;
    top: 10px;
    display: inline; }
  .product-list .searchForm .step3Filters .form-row {
    padding: 1rem 0.5rem 0.5rem 0.5rem; }
  .product-list .searchForm .search-btn-col {
    margin: auto;
    padding-top: 13px; }

.product-list .standard-product-item {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important; }

.product-list .standard-product-row {
  cursor: pointer; }
  .product-list .standard-product-row:hover {
    background-color: #cceffc; }

.bs-actionsbox, .bs-donebutton, .bs-searchbox {
  margin: 0px !important;
  padding: 0px !important; }

.productNumber-pagination {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 50px; }

#page.product-details h5.total {
  margin: 0;
  text-align: right;
  font-size: 1.125rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  #page.product-details h5.total span.currency,
  #page.product-details h5.total span.value {
    font-weight: 600; }

#page.product-details .product-attributes {
  padding-left: 1rem;
  margin-top: 1rem; }
  #page.product-details .product-attributes .product-attribute-group {
    margin-right: 1.2rem;
    margin-bottom: 1.5rem; }
  #page.product-details .product-attributes .product-info {
    padding-left: 1rem;
    padding-top: 0; }

#page.product-details .product-detail-info {
  padding-left: 1rem; }
  #page.product-details .product-detail-info .product-info {
    padding-left: 1rem;
    padding-top: 0; }

#page.product-details .product-quantity {
  padding-bottom: 0.2rem; }

#page.product-details .product-quantity-row .row {
  margin: 0; }

#page.product-details .product-magic-attributes {
  padding-left: 0.6rem;
  margin-top: 1.8rem; }
  #page.product-details .product-magic-attributes.row {
    padding-bottom: 0.75rem; }
  #page.product-details .product-magic-attributes .product-magic-attribute {
    padding-left: 0.6rem;
    padding-bottom: 0.75rem; }
  #page.product-details .product-magic-attributes .center-column {
    padding-left: 0; }
  #page.product-details .product-magic-attributes button {
    margin-left: 0.5rem; }

#page.product-details .magic-attribute {
  margin-left: 1rem; }
  #page.product-details .magic-attribute.label {
    padding-left: 0; }

#page.product-details .last-magic-attribute {
  margin-left: 2rem; }
  #page.product-details .last-magic-attribute.label {
    padding-left: 0; }

#page.product-details th {
  font-weight: 600 !important; }

#page.product-details .variable-data-table {
  margin-top: 1.5rem; }

#page.product-details .care-label-instruction-code-text {
  margin-top: 1.75rem;
  background-color: #f1f1f1; }

#page.product-details .isHidden {
  display: none; }

#page.product-details .cancel-button {
  margin-right: 1rem; }

#page.product-details .invalid-red {
  border-color: #DC3545; }

#page.product-details .product-quantity-row button {
  margin-top: 1.5rem; }

.product-code-list .searchForm {
  background-color: #f1f1f1;
  padding: 35px;
  position: relative; }
  .product-code-list .searchForm button.searchBTN {
    margin-left: 0;
    margin-top: 23px !important; }
  .product-code-list .searchForm #searchButtonCol {
    padding-left: 10px !important; }

.product-code-list #productCodeList td {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important; }

.product-code-list #productCodeList .pc-row {
  cursor: pointer; }
  .product-code-list #productCodeList .pc-row:hover {
    background-color: #cceffc; }

.productCodeNumber-pagination {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px; }

#page.product-code-details #top-level-info {
  display: flex;
  flex-direction: row; }

#page.product-code-details .code-info {
  overflow: auto;
  flex: 1 1 auto !important;
  width: 100%; }
  #page.product-code-details .code-info ul {
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.product-code-details .code-info ul li {
      margin: 0.75rem 1.5rem 0.75rem 1.5rem;
      width: 100%;
      margin-bottom: 0.75rem;
      padding-right: 1rem; }
      #page.product-code-details .code-info ul li .label {
        font-size: 0.875rem;
        font-weight: 600; }
      #page.product-code-details .code-info ul li.stack {
        margin-bottom: 0rem; }

.order-list .searchForm button.searchBTN {
  margin-left: 0;
  margin-top: 20px !important; }

.order-list .searchForm #searchButtonCol {
  padding-left: 10px !important; }

.order-list #orderList .order-item:hover {
  background-color: #cceffc; }
  .order-list #orderList .order-item:hover .order-price {
    background-color: #00aeef; }

.order-list #orderList .order-item .order-details .order-meta ul:first-child {
  width: 75%; }

.order-list #orderList .order-item .order-details .order-meta ul:last-child {
  width: 25%;
  justify-content: flex-end; }

.order-list .download-csv {
  margin-left: 1rem; }

a.toggleBTN {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #009FDF;
  padding: 10px 30px 10px 15px;
  line-height: 40px;
  top: 0;
  left: 0;
  background-color: #f1f1f1; }
  a.toggleBTN #ToggleIcon {
    margin-right: 0.5rem;
    font-size: 0.875rem; }
  a.toggleBTN #ToggleIcon:before {
    font-family: 'Font Awesome 5 Pro';
    content: '\f068'; }
  a.toggleBTN.collapsed #ToggleIcon:before {
    content: '\f067'; }

.order-list .searchForm {
  background-color: #f1f1f1;
  padding: 35px;
  position: relative; }
  .order-list .searchForm h5.filterTitle {
    font-size: .875rem;
    background-color: #f1f1f1;
    position: relative;
    padding: 0px 10px 0px 0px;
    left: -5px;
    z-index: 999;
    top: 10px;
    display: inline; }
  .order-list .searchForm .step3Filters .form-row {
    padding: 1rem 0.5rem 0.5rem 0.5rem; }

.bs-actionsbox, .bs-donebutton, .bs-searchbox {
  margin: 0px !important;
  padding: 0px !important; }

.productNumber-pagination {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px; }

.datepicker-highlight-end-date {
  background-color: #919191; }

.react-datepicker-popper {
  z-index: 99 !important; }

#page.order-details #orderList .order-item {
  padding: 0rem; }
  #page.order-details #orderList .order-item:hover {
    background-color: white; }
  #page.order-details #orderList .order-item .order-details {
    flex: 1 1 auto !important;
    padding: 1.5rem 1.5rem 0.25rem 1.5rem; }
    #page.order-details #orderList .order-item .order-details h5.company {
      font-size: 1rem;
      font-weight: 600; }
    #page.order-details #orderList .order-item .order-details .order-meta {
      display: flex;
      flex-direction: row;
      width: 100%; }
      #page.order-details #orderList .order-item .order-details .order-meta ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; }
        #page.order-details #orderList .order-item .order-details .order-meta ul li {
          margin-bottom: 0.75rem;
          padding-right: 2rem; }
          #page.order-details #orderList .order-item .order-details .order-meta ul li .label {
            font-size: 0.875rem;
            font-weight: 600; }
      #page.order-details #orderList .order-item .order-details .order-meta .status-icons span {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        margin-right: 0.25rem;
        margin-top: 0.2rem; }
        #page.order-details #orderList .order-item .order-details .order-meta .status-icons span.placed.active {
          background-color: #AB3511; }
        #page.order-details #orderList .order-item .order-details .order-meta .status-icons span.art-prepared.active {
          background-color: #E27938; }
        #page.order-details #orderList .order-item .order-details .order-meta .status-icons span.art-approved.active {
          background-color: #E1D35F; }
        #page.order-details #orderList .order-item .order-details .order-meta .status-icons span.production.active {
          background-color: #0F6C91; }
        #page.order-details #orderList .order-item .order-details .order-meta .status-icons span.finance.active {
          background-color: #918A07; }
        #page.order-details #orderList .order-item .order-details .order-meta .status-icons span.shipped.active {
          background-color: #8A12BF; }

#page.order-details .nav-tabs {
  margin: 2rem 0; }

#page.order-details .product-item .general-details .product-img {
  background-color: white;
  width: 30%; }

#page.order-details .product-magic-attributes {
  padding-left: 0.6rem;
  margin-top: 1.8rem; }
  #page.order-details .product-magic-attributes.row {
    padding-bottom: 0.75rem; }
  #page.order-details .product-magic-attributes .product-magic-attribute {
    padding-left: 0.6rem;
    padding-bottom: 0.75rem; }

#page.order-details .last-magic-attribute {
  margin-left: 2rem;
  padding-left: 0; }

#page.order-details .order-care-icon {
  font-size: 1.5rem;
  font-weight: bolder;
  margin-left: 0.9rem;
  margin-right: 0.5rem; }

#page.order-details .order-label-heading {
  font-weight: bold;
  font-size: 16px;
  padding-top: 1em;
  padding-bottom: 0.5em; }

#page.order-details .order-label-sub-heading {
  font-weight: bold;
  font-size: 15px;
  padding-top: 0.2em;
  padding-bottom: 0.5em; }

#page.order-details .special-instruction-read-only {
  margin-top: 2rem;
  padding: 1rem 1rem 0.5rem 0rem;
  background-color: #f1f1f1;
  margin-left: -0.9rem;
  border-radius: .2rem; }

#page.order-details .view-comment {
  max-width: 250px;
  display: inline-flex; }

#page.order-details .comment-label {
  margin-top: 10px; }

#page.order-details .charge-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  #page.order-details .charge-list > li {
    margin-bottom: 1.5rem; }

#page.order-details .order-charge {
  padding: 1.5rem;
  width: 100%; }
  #page.order-details .order-charge .label {
    font-size: 0.875rem;
    font-weight: 600; }
  #page.order-details .order-charge ul {
    width: 100%;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.order-details .order-charge ul li {
      margin-bottom: 0.75rem;
      padding-right: 1rem;
      width: 25%; }
      #page.order-details .order-charge ul li:last-child {
        justify-content: flex-end;
        text-align: right; }
        #page.order-details .order-charge ul li:last-child div {
          display: inline; }

#page.order-details #shipping,
#page.order-details #payment,
#page.order-details #related {
  margin-bottom: 1.5rem; }
  #page.order-details #shipping h2,
  #page.order-details #payment h2,
  #page.order-details #related h2 {
    margin-bottom: 1.5rem; }
  #page.order-details #shipping ul.readonly,
  #page.order-details #payment ul.readonly,
  #page.order-details #related ul.readonly {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    #page.order-details #shipping ul.readonly li,
    #page.order-details #payment ul.readonly li,
    #page.order-details #related ul.readonly li {
      width: 100%;
      display: flex;
      margin-bottom: 0.75rem; }
      #page.order-details #shipping ul.readonly li .label,
      #page.order-details #payment ul.readonly li .label,
      #page.order-details #related ul.readonly li .label {
        width: 150px;
        font-size: 0.875rem;
        font-weight: 600; }
      #page.order-details #shipping ul.readonly li .flex-list-item,
      #page.order-details #payment ul.readonly li .flex-list-item,
      #page.order-details #related ul.readonly li .flex-list-item {
        margin-bottom: 0em; }
  #page.order-details #shipping .docs-upload,
  #page.order-details #payment .docs-upload,
  #page.order-details #related .docs-upload {
    align-self: center;
    margin-bottom: 1rem; }
  #page.order-details #shipping .flex-list,
  #page.order-details #payment .flex-list,
  #page.order-details #related .flex-list {
    list-style-type: none;
    padding-left: 1em; }

#page.order-details .total .price-label {
  font-weight: normal; }

#page.order-details .order-logs {
  margin-bottom: 1.5rem; }
  #page.order-details .order-logs h2 {
    margin-bottom: 1.5rem; }
  #page.order-details .order-logs .label {
    font-weight: 600;
    font-size: 0.875rem; }
  #page.order-details .order-logs .log-entry {
    margin-bottom: 0.5rem; }
  #page.order-details .order-logs .log-header {
    margin-bottom: 0.75rem; }

#page .cancelled {
  margin: auto;
  width: 200px;
  text-align: center;
  color: #DC3545;
  font-size: 2rem; }

#page .cancelled-item {
  font-size: 1.4rem;
  color: #DC3545;
  padding-left: 1rem; }

#page .header-item {
  font-weight: 600;
  margin-bottom: 0.5rem !important; }

#page #orderStatus {
  overflow: hidden;
  display: flex;
  flex-wrap: no-wrap;
  background-color: white;
  border-radius: .225rem;
  margin-bottom: 2%;
  border: 1px solid #b1b1b1; }
  #page #orderStatus .placed {
    position: relative;
    font-weight: bold;
    font-size: 1.12rem;
    line-height: 6rem !important;
    text-align: center;
    flex-grow: 1; }
    #page #orderStatus .placed.active {
      background-color: #AB3511;
      color: white; }
      #page #orderStatus .placed.active.active::after {
        content: "";
        position: absolute;
        width: auto;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        top: -1px;
        right: -30px;
        z-index: 1000;
        -webkit-transform: rotate(0deg);
        z-index: 9999;
        border-left: 30px solid #AB3511;
        right: -30px; }
    #page #orderStatus .placed::before {
      content: "";
      position: absolute;
      width: auto;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      top: -1px;
      right: -30px;
      z-index: 1000;
      -webkit-transform: rotate(0deg);
      border-left: 30px solid white;
      z-index: 9999; }
    #page #orderStatus .placed::after {
      content: "";
      position: absolute;
      width: auto;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      top: -1px;
      right: -30px;
      z-index: 1000;
      -webkit-transform: rotate(0deg);
      border-left: 30px solid white;
      right: -31px;
      border-left: 30px solid #b1b1b1; }
  #page #orderStatus .art-prepared {
    position: relative;
    font-weight: bold;
    font-size: 1.12rem;
    line-height: 6rem !important;
    text-align: center;
    flex-grow: 1;
    padding-left: 2rem; }
    #page #orderStatus .art-prepared.active {
      background-color: #E27938;
      color: white; }
      #page #orderStatus .art-prepared.active.active::after {
        content: "";
        position: absolute;
        width: auto;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        top: -1px;
        right: -30px;
        z-index: 1000;
        -webkit-transform: rotate(0deg);
        z-index: 9999;
        right: -30px;
        border-left: 30px solid #E27938; }
    #page #orderStatus .art-prepared::before {
      content: "";
      position: absolute;
      width: auto;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      top: -1px;
      right: -30px;
      z-index: 1000;
      -webkit-transform: rotate(0deg);
      border-left: 30px solid white;
      z-index: 9999; }
    #page #orderStatus .art-prepared::after {
      content: "";
      position: absolute;
      width: auto;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      top: -1px;
      right: -30px;
      z-index: 1000;
      -webkit-transform: rotate(0deg);
      border-left: 30px solid white;
      right: -31px;
      border-left: 30px solid #b1b1b1; }
  #page #orderStatus .art-approved {
    position: relative;
    font-weight: bold;
    font-size: 1.12rem;
    line-height: 6rem !important;
    text-align: center;
    flex-grow: 1;
    padding-left: 2rem; }
    #page #orderStatus .art-approved.active {
      background-color: #E1D35F;
      color: white; }
      #page #orderStatus .art-approved.active.active::after {
        content: "";
        position: absolute;
        width: auto;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        top: -1px;
        right: -30px;
        z-index: 1000;
        -webkit-transform: rotate(0deg);
        z-index: 9999;
        right: -30px;
        border-left: 30px solid #E1D35F; }
    #page #orderStatus .art-approved::before {
      content: "";
      position: absolute;
      width: auto;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      top: -1px;
      right: -30px;
      z-index: 1000;
      -webkit-transform: rotate(0deg);
      border-left: 30px solid white;
      z-index: 9999; }
    #page #orderStatus .art-approved::after {
      content: "";
      position: absolute;
      width: auto;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      top: -1px;
      right: -30px;
      z-index: 1000;
      -webkit-transform: rotate(0deg);
      border-left: 30px solid white;
      right: -31px;
      border-left: 30px solid #b1b1b1; }
  #page #orderStatus .production {
    position: relative;
    font-weight: bold;
    font-size: 1.12rem;
    line-height: 6rem !important;
    text-align: center;
    flex-grow: 1;
    padding-left: 2rem; }
    #page #orderStatus .production.active {
      background-color: #0F6C91;
      color: white; }
      #page #orderStatus .production.active.active::after {
        content: "";
        position: absolute;
        width: auto;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        top: -1px;
        right: -30px;
        z-index: 1000;
        -webkit-transform: rotate(0deg);
        z-index: 9999;
        right: -30px;
        border-left: 30px solid #0F6C91; }
    #page #orderStatus .production::before {
      content: "";
      position: absolute;
      width: auto;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      top: -1px;
      right: -30px;
      z-index: 1000;
      -webkit-transform: rotate(0deg);
      border-left: 30px solid white;
      z-index: 9999; }
    #page #orderStatus .production::after {
      content: "";
      position: absolute;
      width: auto;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      top: -1px;
      right: -30px;
      z-index: 1000;
      -webkit-transform: rotate(0deg);
      border-left: 30px solid white;
      right: -31px;
      border-left: 30px solid #b1b1b1; }
  #page #orderStatus .finance {
    position: relative;
    font-weight: bold;
    font-size: 1.12rem;
    line-height: 6rem !important;
    text-align: center;
    flex-grow: 1;
    padding-left: 2rem; }
    #page #orderStatus .finance.active {
      background-color: #918A07;
      color: white; }
      #page #orderStatus .finance.active.active::after {
        content: "";
        position: absolute;
        width: auto;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        top: -1px;
        right: -30px;
        z-index: 1000;
        -webkit-transform: rotate(0deg);
        z-index: 9999;
        right: -30px;
        border-left: 30px solid #918A07; }
    #page #orderStatus .finance::before {
      content: "";
      position: absolute;
      width: auto;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      top: -1px;
      right: -30px;
      z-index: 1000;
      -webkit-transform: rotate(0deg);
      border-left: 30px solid white;
      z-index: 9999; }
    #page #orderStatus .finance::after {
      content: "";
      position: absolute;
      width: auto;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      top: -1px;
      right: -30px;
      z-index: 1000;
      -webkit-transform: rotate(0deg);
      border-left: 30px solid white;
      right: -31px;
      border-left: 30px solid #b1b1b1; }
  #page #orderStatus .shipped {
    position: relative;
    font-weight: bold;
    font-size: 1.12rem;
    line-height: 6rem !important;
    text-align: center;
    flex-grow: 1;
    padding-left: 2rem; }
    #page #orderStatus .shipped.active {
      background-color: #8A12BF;
      color: white; }
      #page #orderStatus .shipped.active.active::after {
        content: "";
        position: absolute;
        width: auto;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        top: -1px;
        right: -30px;
        z-index: 1000;
        -webkit-transform: rotate(0deg);
        z-index: 9999;
        right: -30px;
        border-left: 30px solid #8A12BF; }
    #page #orderStatus .shipped::before {
      content: "";
      position: absolute;
      width: auto;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      top: -1px;
      right: -30px;
      z-index: 1000;
      -webkit-transform: rotate(0deg);
      border-left: 30px solid white;
      z-index: 9999; }
    #page #orderStatus .shipped::after {
      content: "";
      position: absolute;
      width: auto;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      top: -1px;
      right: -30px;
      z-index: 1000;
      -webkit-transform: rotate(0deg);
      border-left: 30px solid white;
      right: -31px;
      border-left: 30px solid #b1b1b1; }

#page a.clickable {
  cursor: pointer; }

#page.restock-page .card .row {
  margin-top: 0.75rem;
  margin-bottom: 1rem;
  padding-left: 2rem; }
  #page.restock-page .card .row.first {
    margin-top: 2rem; }
  #page.restock-page .card .row label {
    margin-bottom: 0.5rem; }

#page.cart #orderList .order-item {
  padding: 0rem; }
  #page.cart #orderList .order-item:hover {
    background-color: white; }

#page.cart .nav-tabs {
  margin: 2rem 0; }

#page.cart .product-item .general-details .product-img {
  background-color: white; }

#page.cart .charge-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  #page.cart .charge-list > li {
    margin-bottom: 1.5rem; }

#page.cart .order-charge {
  padding: 1.5rem;
  width: 100%; }
  #page.cart .order-charge .label {
    font-size: 0.875rem;
    font-weight: 600; }
  #page.cart .order-charge ul {
    width: 100%;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.cart .order-charge ul li {
      margin-bottom: 0.75rem;
      padding-right: 1rem;
      width: 25%; }
      #page.cart .order-charge ul li:last-child {
        justify-content: flex-end;
        text-align: right; }
        #page.cart .order-charge ul li:last-child div {
          display: inline; }

#page.cart .product-magic-attributes {
  padding-left: 0.6rem;
  margin-top: 1.8rem; }
  #page.cart .product-magic-attributes.row {
    padding-bottom: 0.75rem; }
  #page.cart .product-magic-attributes .product-magic-attribute {
    padding-left: 0.6rem;
    padding-bottom: 0.75rem; }

#page.cart .last-magic-attribute {
  margin-left: 2rem;
  padding-left: 0; }

#page.cart .order-care-icon {
  font-size: 1.5rem;
  font-weight: bolder;
  margin-left: 0.9rem;
  margin-right: 0.5rem; }

#page.cart .order-label-heading {
  font-weight: bold;
  font-size: 16px;
  padding-top: 1em;
  padding-bottom: 0.5em; }

#page.cart .order-label-sub-heading {
  font-weight: bold;
  font-size: 15px;
  padding-top: 0.2em;
  padding-bottom: 0.5em; }

#page.cart .special-instruction-read-only {
  margin-top: 2rem;
  padding: 1rem 1rem 0.5rem 0rem;
  background-color: #f1f1f1;
  margin-left: -0.9rem;
  border-radius: .2rem; }

#page.cart #shipping,
#page.cart #payment,
#page.cart #related {
  margin-bottom: 1.5rem; }
  #page.cart #shipping h2,
  #page.cart #payment h2,
  #page.cart #related h2 {
    margin-bottom: 1.5rem; }
  #page.cart #shipping ul.readonly,
  #page.cart #payment ul.readonly,
  #page.cart #related ul.readonly {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    #page.cart #shipping ul.readonly li,
    #page.cart #payment ul.readonly li,
    #page.cart #related ul.readonly li {
      width: 100%;
      display: flex;
      margin-bottom: 0.75rem; }
      #page.cart #shipping ul.readonly li .label,
      #page.cart #payment ul.readonly li .label,
      #page.cart #related ul.readonly li .label {
        width: 150px;
        font-size: 0.875rem;
        font-weight: 600; }
      #page.cart #shipping ul.readonly li .flex-list-item,
      #page.cart #payment ul.readonly li .flex-list-item,
      #page.cart #related ul.readonly li .flex-list-item {
        margin-bottom: 0em; }
  #page.cart #shipping .docs-upload,
  #page.cart #payment .docs-upload,
  #page.cart #related .docs-upload {
    align-self: center;
    margin-bottom: 1rem; }
  #page.cart #shipping .flex-list,
  #page.cart #payment .flex-list,
  #page.cart #related .flex-list {
    list-style-type: none;
    padding-left: 1em; }

#page.variable-data-upload .card .row {
  margin-top: 0.75rem;
  margin-bottom: 1rem;
  padding-left: 2rem; }
  #page.variable-data-upload .card .row.first {
    margin-top: 2rem; }
  #page.variable-data-upload .card .row label {
    margin-bottom: 0.5rem; }

.user-list .searchForm button.searchBTN {
  margin-left: 0;
  margin-top: 20px !important; }

.user-list .searchForm #searchButtonCol {
  padding-left: 10px !important; }

.user-list #userList .user-item:hover {
  background-color: #cceffc; }

.user-list #userList .user-item .user-details .user-meta ul:first-child {
  width: 100%; }

a.toggleBTN {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #009FDF;
  padding: 10px 30px 10px 15px;
  line-height: 40px;
  top: 0;
  left: 0;
  background-color: #f1f1f1; }
  a.toggleBTN #ToggleIcon {
    margin-right: 0.5rem;
    font-size: 0.875rem; }
  a.toggleBTN #ToggleIcon:before {
    font-family: 'Font Awesome 5 Pro';
    content: '\f068'; }
  a.toggleBTN.collapsed #ToggleIcon:before {
    content: '\f067'; }

.user-list .searchForm {
  background-color: #f1f1f1;
  padding: 35px;
  position: relative; }
  .user-list .searchForm h5.filterTitle {
    font-size: .875rem;
    background-color: #f1f1f1;
    position: relative;
    padding: 0px 10px 0px 0px;
    left: -5px;
    z-index: 999;
    top: 10px;
    display: inline; }
  .user-list .searchForm .step3Filters .form-row {
    padding: 1rem 0.5rem 0.5rem 0.5rem; }

.bs-actionsbox, .bs-donebutton, .bs-searchbox {
  margin: 0px !important;
  padding: 0px !important; }

.userNumber-pagination {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px; }

.customer-list .searchForm button.searchBTN {
  margin-left: 0;
  margin-top: 20px !important; }

.customer-list .searchForm #searchButtonCol {
  padding-left: 10px !important; }

.customer-list #customerList .customer-item:hover {
  background-color: #cceffc; }

.customer-list #customerList .customer-item .customer-details .customer-meta ul:first-child {
  width: 100%; }

a.toggleBTN {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #009FDF;
  padding: 10px 30px 10px 15px;
  line-height: 40px;
  top: 0;
  left: 0;
  background-color: #f1f1f1; }
  a.toggleBTN #ToggleIcon {
    margin-right: 0.5rem;
    font-size: 0.875rem; }
  a.toggleBTN #ToggleIcon:before {
    font-family: 'Font Awesome 5 Pro';
    content: '\f068'; }
  a.toggleBTN.collapsed #ToggleIcon:before {
    content: '\f067'; }

.customer-list .searchForm {
  background-color: #f1f1f1;
  padding: 35px;
  position: relative; }
  .customer-list .searchForm h5.filterTitle {
    font-size: .875rem;
    background-color: #f1f1f1;
    position: relative;
    padding: 0px 10px 0px 0px;
    left: -5px;
    z-index: 999;
    top: 10px;
    display: inline; }
  .customer-list .searchForm .step3Filters .form-row {
    padding: 1rem 0.5rem 0.5rem 0.5rem; }

.bs-actionsbox, .bs-donebutton, .bs-searchbox {
  margin: 0px !important;
  padding: 0px !important; }

.customerNumber-pagination {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px; }

#page.customer-details #top-level-info {
  display: flex;
  flex-direction: row; }

#page.customer-details .customer-info {
  overflow: auto;
  flex: 1 1 auto !important;
  width: 100%; }
  #page.customer-details .customer-info ul {
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.customer-details .customer-info ul li {
      margin: 0.75rem 1.5rem 0.75rem 1.5rem;
      width: 100%;
      margin-bottom: 0.75rem;
      padding-right: 1rem; }
      #page.customer-details .customer-info ul li .label {
        font-size: 0.875rem;
        font-weight: 600; }
      #page.customer-details .customer-info ul li.stack {
        margin-bottom: 0rem; }

#page.customer-details .entity-list .row {
  margin: 2rem 0rem; }

#page.customer-details .entity-list label {
  font-size: 0.875rem;
  font-weight: 600; }

#page.customer-details .entity-list .scroller {
  margin: 0 0;
  height: 450px;
  width: 100%;
  overflow: scroll; }

#page.customer-details .entity-list .checkbox {
  margin-right: 10px; }

#page.customer-details .entity-list .radio {
  margin-right: 10px; }

#page.customer-details .entity-list .item {
  padding: 10px 10px; }

#page.customer-details .entity-list .item:nth-child(odd) {
  background-color: #f4f4f4; }

#page.user-details #top-level-info {
  display: flex;
  flex-direction: row; }

#page.user-details .user-info {
  overflow: auto;
  flex: 1 1 auto !important;
  width: 100%; }
  #page.user-details .user-info ul {
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.user-details .user-info ul li {
      margin: 0.75rem 1.5rem 0.75rem 1.5rem;
      width: 100%;
      margin-bottom: 0.75rem;
      padding-right: 1rem; }
      #page.user-details .user-info ul li .label {
        font-size: 0.875rem;
        font-weight: 600; }
      #page.user-details .user-info ul li.stack {
        margin-bottom: 0rem; }

#page.user-details .entity-list .row {
  margin: 2rem 0rem; }

#page.user-details .entity-list label {
  font-size: 0.875rem;
  font-weight: 600; }

#page.user-details .entity-list .scroller {
  margin: 0 0;
  height: 450px;
  width: 100%;
  overflow: scroll; }

#page.user-details .entity-list .checkbox {
  margin-right: 10px; }

#page.user-details .entity-list .radio {
  margin-right: 10px; }

#page.user-details .entity-list .item {
  padding: 10px 10px; }

#page.user-details .entity-list .item:nth-child(odd) {
  background-color: #f4f4f4; }

a.toggleBTN {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #009FDF;
  padding: 10px 30px 10px 15px;
  line-height: 40px;
  top: 0;
  left: 0;
  background-color: #f1f1f1; }
  a.toggleBTN #ToggleIcon {
    margin-right: 0.5rem;
    font-size: 0.875rem; }
  a.toggleBTN #ToggleIcon:before {
    font-family: "Font Awesome 5 Pro";
    content: "\f068"; }
  a.toggleBTN.collapsed #ToggleIcon:before {
    content: "\f067"; }

#page.vendor-list .searchForm {
  background-color: #f1f1f1;
  padding: 35px;
  position: relative; }
  #page.vendor-list .searchForm h5.filterTitle {
    font-size: 0.875rem;
    background-color: #f1f1f1;
    position: relative;
    padding: 0px 10px 0px 0px;
    left: -5px;
    z-index: 999;
    top: 10px;
    display: inline; }
  #page.vendor-list .searchForm .step3Filters .form-row {
    padding: 1rem 0.5rem 0.5rem 0.5rem; }

#page #vendorList .vendor-item:hover {
  background-color: #cceffc; }

#page #vendorList .vendor-item .vendor-details {
  min-height: 8rem; }
  #page #vendorList .vendor-item .vendor-details .vendor-meta ul:first-child {
    width: 100%; }

#page .bs-actionsbox,
#page .bs-donebutton,
#page .bs-searchbox {
  margin: 0px !important;
  padding: 0px !important; }

#page .vendorNumber-pagination {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px; }

#page.vendor-details #top-level-info {
  display: flex;
  flex-direction: row; }

#page.vendor-details .vendor-info {
  overflow: auto;
  flex: 1 1 auto !important;
  width: 100%; }
  #page.vendor-details .vendor-info ul {
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.vendor-details .vendor-info ul li {
      margin: 0.75rem 1.5rem 0.75rem 1.5rem;
      width: 100%;
      margin-bottom: 0.75rem;
      padding-right: 1rem; }
      #page.vendor-details .vendor-info ul li .label {
        font-size: 0.875rem;
        font-weight: 600; }
      #page.vendor-details .vendor-info ul li.stack {
        margin-bottom: 0rem; }

#page.vendor-details .entity-list .row {
  margin: 2rem 0rem; }

#page.vendor-details .entity-list label {
  font-size: 0.875rem;
  font-weight: 600; }

#page.vendor-details .entity-list .scroller {
  margin: 0 0;
  height: 450px;
  width: 100%;
  overflow: scroll; }

#page.vendor-details .entity-list .checkbox {
  margin-right: 10px; }

#page.vendor-details .entity-list .radio {
  margin-right: 10px; }

#page.vendor-details .entity-list .item {
  padding: 10px 10px; }

#page.vendor-details .entity-list .item:nth-child(odd) {
  background-color: #f4f4f4; }

#page.vendor-details .entity-list .scroller {
  height: 504px; }

#page.vendor-details .entity-list #entity-buttons,
#page.vendor-details .entity-list .entity-buttons {
  justify-content: space-between;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 1rem;
  margin-top: 0.5rem; }
  #page.vendor-details .entity-list #entity-buttons button,
  #page.vendor-details .entity-list .entity-buttons button {
    padding: 0; }
    #page.vendor-details .entity-list #entity-buttons button:disabled,
    #page.vendor-details .entity-list .entity-buttons button:disabled {
      pointer-events: none; }

#page.vendor-details .entity-list .table-responsive {
  height: 450px;
  overflow-y: scroll; }
  #page.vendor-details .entity-list .table-responsive .entity-table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white; }
  #page.vendor-details .entity-list .table-responsive .entity-table tr.highlight {
    cursor: pointer; }
    #page.vendor-details .entity-list .table-responsive .entity-table tr.highlight:hover {
      background-color: #cceffc; }

.factory-list .searchForm {
  background-color: #f1f1f1;
  padding: 35px;
  position: relative; }
  .factory-list .searchForm button.searchBTN {
    margin-left: 0;
    margin-top: 23px !important; }
  .factory-list .searchForm #searchButtonCol {
    padding-left: 10px !important; }

.factory-list #FactoryList td {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important; }

.factory-list #FactoryList .pf-row {
  cursor: pointer; }
  .factory-list #FactoryList .pf-row:hover {
    background-color: #cceffc; }

.factoryNumber-pagination {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px; }

.warehouse-list .searchForm {
  background-color: #f1f1f1;
  padding: 35px;
  position: relative; }
  .warehouse-list .searchForm button.searchBTN {
    margin-left: 0;
    margin-top: 23px !important; }
  .warehouse-list .searchForm #searchButtonCol {
    padding-left: 10px !important; }

.warehouse-list #WarehouseList td {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important; }

.warehouse-list #WarehouseList .pf-row {
  cursor: pointer; }
  .warehouse-list #WarehouseList .pf-row:hover {
    background-color: #cceffc; }

.warehouseNumber-pagination {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px; }

#page.factory-details #top-level-info {
  display: flex;
  flex-direction: row; }

#page.factory-details .factory-info {
  overflow: auto;
  flex: 1 1 auto !important;
  width: 100%; }
  #page.factory-details .factory-info ul {
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.factory-details .factory-info ul li {
      margin: 0.75rem 1.5rem 0.75rem 1.5rem;
      width: 100%;
      margin-bottom: 0.75rem;
      padding-right: 1rem; }
      #page.factory-details .factory-info ul li .label {
        font-size: 0.875rem;
        font-weight: 600; }
      #page.factory-details .factory-info ul li.stack {
        margin-bottom: 0rem; }

#page.factory-details .entity-list .row {
  margin: 2rem 0rem; }

#page.factory-details .entity-list label {
  font-size: 0.875rem;
  font-weight: 600; }

#page.factory-details .entity-list .scroller {
  margin: 0 0;
  height: 450px;
  width: 100%;
  overflow: scroll; }

#page.factory-details .entity-list .checkbox {
  margin-right: 10px; }

#page.factory-details .entity-list .radio {
  margin-right: 10px; }

#page.factory-details .entity-list .item {
  padding: 10px 10px; }

#page.factory-details .entity-list .item:nth-child(odd) {
  background-color: #f4f4f4; }

.product-subcode-list .searchForm {
  background-color: #f1f1f1;
  padding: 35px;
  position: relative; }
  .product-subcode-list .searchForm button.searchBTN {
    margin-left: 0;
    margin-top: 23px !important; }
  .product-subcode-list .searchForm #searchButtonCol {
    padding-left: 10px !important; }

.product-subcode-list #SubcodeList {
  table-layout: fixed; }
  .product-subcode-list #SubcodeList td {
    padding-top: 0.7rem !important;
    padding-bottom: 0.7rem !important; }
  .product-subcode-list #SubcodeList .sc-row {
    cursor: pointer;
    height: 4em; }
    .product-subcode-list #SubcodeList .sc-row:hover {
      background-color: #cceffc; }

.subcodeNumber-pagination {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px; }

#page.product-subcode-details #top-level-info {
  display: flex;
  flex-direction: row; }
  #page.product-subcode-details #top-level-info .thumbnail-image {
    max-height: 10rem;
    max-width: 10rem;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  #page.product-subcode-details #top-level-info .file-selected {
    display: block;
    margin-left: 0.8rem;
    font-size: 0.8rem; }
  #page.product-subcode-details #top-level-info .upload-btn {
    display: block;
    width: 90%;
    min-width: 11rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border-radius: 0.2rem;
    margin-bottom: 4px;
    margin-top: 8px; }
    #page.product-subcode-details #top-level-info .upload-btn:disabled {
      pointer-events: none; }
  #page.product-subcode-details #top-level-info .upload-btn-mt {
    display: block;
    width: 90%;
    min-width: 11rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border-radius: 0.2rem;
    margin-bottom: 4px;
    margin-top: 2rem; }
    #page.product-subcode-details #top-level-info .upload-btn-mt:disabled {
      pointer-events: none; }
  #page.product-subcode-details #top-level-info li:first-child {
    margin-bottom: 4rem; }

#page.product-subcode-details .subcode-info {
  overflow: auto;
  flex: 1 1 auto !important;
  width: 100%;
  margin-top: 0.5rem; }
  #page.product-subcode-details .subcode-info ul {
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.product-subcode-details .subcode-info ul li {
      margin: 0.75rem 1.5rem 0.75rem 1.5rem;
      width: 100%;
      margin-bottom: 0.75rem;
      padding-right: 1rem; }
      #page.product-subcode-details .subcode-info ul li .label {
        font-size: 0.875rem;
        font-weight: 600; }
      #page.product-subcode-details .subcode-info ul li.stack {
        margin-bottom: 0rem; }

#page.product-subcode-details .entity-list .row {
  margin: 2rem 0rem; }

#page.product-subcode-details .entity-list label {
  font-size: 0.875rem;
  font-weight: 600; }

#page.product-subcode-details .entity-list .scroller {
  margin: 0 0;
  height: 450px;
  width: 100%;
  overflow: scroll; }

#page.product-subcode-details .entity-list .checkbox {
  margin-right: 10px; }

#page.product-subcode-details .entity-list .radio {
  margin-right: 10px; }

#page.product-subcode-details .entity-list .item {
  padding: 10px 10px; }

#page.product-subcode-details .entity-list .item:nth-child(odd) {
  background-color: #f4f4f4; }

#page.product-subcode-details .price-cost-tiers label {
  font-size: 0.875rem;
  font-weight: 600; }

#page.product-subcode-details .price-cost-tiers .currency-select {
  overflow: auto;
  flex: 1 1 auto !important;
  width: 100%; }
  #page.product-subcode-details .price-cost-tiers .currency-select ul {
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.product-subcode-details .price-cost-tiers .currency-select ul li {
      margin: 0.75rem 1.5rem 0.75rem 1.5rem;
      width: 100%;
      margin-bottom: 0.75rem;
      padding-right: 1rem; }
      #page.product-subcode-details .price-cost-tiers .currency-select ul li .label {
        font-size: 0.875rem;
        font-weight: 600; }
      #page.product-subcode-details .price-cost-tiers .currency-select ul li.stack {
        margin-bottom: 0rem; }
  #page.product-subcode-details .price-cost-tiers .currency-select .label {
    margin-right: 8px; }
  #page.product-subcode-details .price-cost-tiers .currency-select select {
    display: inline;
    width: 7rem; }
  #page.product-subcode-details .price-cost-tiers .currency-select input {
    display: inline;
    width: 9.6rem; }
  #page.product-subcode-details .price-cost-tiers .currency-select ul {
    margin-bottom: 0; }
    #page.product-subcode-details .price-cost-tiers .currency-select ul li {
      width: auto;
      margin-left: 1rem; }
  #page.product-subcode-details .price-cost-tiers .currency-select div {
    display: inline; }

#page.product-subcode-details .price-cost-tiers .field-row {
  align-items: center;
  margin-bottom: 2rem; }
  #page.product-subcode-details .price-cost-tiers .field-row .quantity-field {
    margin: 8px;
    width: 28%; }
  #page.product-subcode-details .price-cost-tiers .field-row .price-field {
    margin: 8px;
    width: 28%; }
  #page.product-subcode-details .price-cost-tiers .field-row .add-tier-btn {
    margin-right: 8px;
    margin-left: 8px;
    padding: 0.5rem 3px;
    height: 2.4rem;
    width: 5rem; }
    #page.product-subcode-details .price-cost-tiers .field-row .add-tier-btn:disabled {
      pointer-events: none; }
  #page.product-subcode-details .price-cost-tiers .field-row .remove-tier-btn {
    width: 5rem;
    height: 2.4rem;
    padding: 0.5rem 3px; }
    #page.product-subcode-details .price-cost-tiers .field-row .remove-tier-btn:disabled {
      pointer-events: none; }

#page.product-subcode-details .price-cost-tiers .tierTable {
  table-layout: fixed;
  width: 90%; }
  #page.product-subcode-details .price-cost-tiers .tierTable .tier-row {
    cursor: pointer; }
    #page.product-subcode-details .price-cost-tiers .tierTable .tier-row:hover {
      background-color: #cceffc; }

#page.product-subcode-details label {
  font-size: 0.875rem;
  font-weight: 600; }

#page.product-subcode-details #ProductItemList td {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important; }

#page.product-subcode-details #ProductItemList .pf-row {
  cursor: pointer; }
  #page.product-subcode-details #ProductItemList .pf-row:hover {
    background-color: #cceffc; }

body .bodyWrapTerms {
  background: url(/static/media/bg-1.88f46178.jpg) left top no-repeat, url(/static/media/bg-2.c57092d4.jpg) right bottom no-repeat;
  background-attachment: fixed; }

#page.terms-and-conditions {
  width: 100%; }

#page .legal-text {
  width: 40rem;
  margin: auto;
  margin-top: 4rem; }

#page h4 {
  margin-bottom: 10px; }

#page .updated-date {
  font-weight: bold;
  margin-bottom: 1rem; }

#page.warehouse-details #top-level-info {
  display: flex;
  flex-direction: row; }

#page.warehouse-details .warehouse-info {
  overflow: auto;
  flex: 1 1 auto !important;
  width: 100%; }
  #page.warehouse-details .warehouse-info ul {
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.warehouse-details .warehouse-info ul li {
      margin: 0.75rem 1.5rem 0.75rem 1.5rem;
      width: 100%;
      margin-bottom: 0.75rem;
      padding-right: 1rem; }
      #page.warehouse-details .warehouse-info ul li .label {
        font-size: 0.875rem;
        font-weight: 600; }
      #page.warehouse-details .warehouse-info ul li.stack {
        margin-bottom: 0rem; }

#page.warehouse-details .long-warehouse-info {
  overflow: auto;
  flex: 1 1 auto !important;
  width: 100%;
  height: 170px;
  overflow: hidden; }
  #page.warehouse-details .long-warehouse-info ul {
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.warehouse-details .long-warehouse-info ul li {
      margin: 0.75rem 1.5rem 0.75rem 1.5rem;
      width: 100%;
      margin-bottom: 0.75rem;
      padding-right: 1rem;
      flex-direction: row; }
      #page.warehouse-details .long-warehouse-info ul li .label {
        font-size: 0.875rem;
        font-weight: 600; }
      #page.warehouse-details .long-warehouse-info ul li.stack {
        margin-bottom: 0rem; }

#page.warehouse-details .entity-list .row {
  margin: 2rem 0rem; }

#page.warehouse-details .entity-list label {
  font-size: 0.875rem;
  font-weight: 600; }

#page.warehouse-details .entity-list .scroller {
  margin: 0 0;
  height: 450px;
  width: 100%;
  overflow: scroll; }

#page.warehouse-details .entity-list .checkbox {
  margin-right: 10px; }

#page.warehouse-details .entity-list .radio {
  margin-right: 10px; }

#page.warehouse-details .entity-list .item {
  padding: 10px 10px; }

#page.warehouse-details .entity-list .item:nth-child(odd) {
  background-color: #f4f4f4; }

.production-region-list .searchForm {
  background-color: #f1f1f1;
  padding: 35px;
  position: relative; }
  .production-region-list .searchForm button.searchBTN {
    margin-left: 0;
    margin-top: 23px !important; }
  .production-region-list .searchForm #searchButtonCol {
    padding-left: 10px !important; }

.production-region-list #ProductionRegionList td {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important; }

.production-region-list #ProductionRegionList .pf-row {
  cursor: pointer; }
  .production-region-list #ProductionRegionList .pf-row:hover {
    background-color: #cceffc; }

.productionRegion-pagination {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px; }

#page.production-region-details #top-level-info {
  display: flex;
  flex-direction: row; }

#page.production-region-details .production-region-info {
  overflow: auto;
  flex: 1 1 auto !important;
  width: 100%; }
  #page.production-region-details .production-region-info ul {
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.production-region-details .production-region-info ul li {
      margin: 0.75rem 1.5rem 0.75rem 1.5rem;
      width: 100%;
      margin-bottom: 0.75rem;
      padding-right: 1rem; }
      #page.production-region-details .production-region-info ul li .label {
        font-size: 0.875rem;
        font-weight: 600; }
      #page.production-region-details .production-region-info ul li.stack {
        margin-bottom: 0rem; }

#page.production-region-details .entity-list .row {
  margin: 2rem 0rem; }

#page.production-region-details .entity-list label {
  font-size: 0.875rem;
  font-weight: 600; }

#page.production-region-details .entity-list .scroller {
  margin: 0 0;
  height: 450px;
  width: 100%;
  overflow: scroll; }

#page.production-region-details .entity-list .checkbox {
  margin-right: 10px; }

#page.production-region-details .entity-list .radio {
  margin-right: 10px; }

#page.production-region-details .entity-list .item {
  padding: 10px 10px; }

#page.production-region-details .entity-list .item:nth-child(odd) {
  background-color: #f4f4f4; }

#page.care-label-configuration label {
  font-size: 0.875rem;
  font-weight: 600; }

#page.care-label-configuration #entity-buttons, #page.care-label-configuration .entity-buttons {
  justify-content: space-between;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 1rem;
  margin-top: 0.5rem; }
  #page.care-label-configuration #entity-buttons button, #page.care-label-configuration .entity-buttons button {
    padding: 0; }
    #page.care-label-configuration #entity-buttons button:disabled, #page.care-label-configuration .entity-buttons button:disabled {
      pointer-events: none; }

#page.care-label-configuration .table-responsive {
  height: 450px;
  overflow-y: scroll; }
  #page.care-label-configuration .table-responsive .entity-table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white; }
  #page.care-label-configuration .table-responsive .entity-table tr.highlight {
    cursor: pointer; }
    #page.care-label-configuration .table-responsive .entity-table tr.highlight:hover {
      background-color: #cceffc; }

#page.care-label-configuration #fabric-composition-lists-row .table-responsive {
  margin-bottom: 0rem; }

#page.care-label-configuration .entity-list .row {
  margin: 2rem 0rem; }

#page.care-label-configuration .entity-list label {
  font-size: 0.875rem;
  font-weight: 600; }

#page.care-label-configuration .entity-list .scroller {
  margin: 0 0;
  height: 450px;
  width: 100%;
  overflow: scroll; }

#page.care-label-configuration .entity-list .checkbox {
  margin-right: 10px; }

#page.care-label-configuration .entity-list .radio {
  margin-right: 10px; }

#page.care-label-configuration .entity-list .item {
  padding: 10px 10px; }

#page.care-label-configuration .entity-list .item:nth-child(odd) {
  background-color: #f4f4f4; }

#page.care-label-configuration .entity-list .item.disabled {
  color: darkgrey; }

#page.care-label-configuration .entity-list .table-responsive {
  height: 100px;
  margin-bottom: 1rem; }

.product-item-list .searchForm {
  background-color: #f1f1f1;
  padding: 35px;
  position: relative; }
  .product-item-list .searchForm button.searchBTN {
    margin-left: 0;
    margin-top: 23px !important; }
  .product-item-list .searchForm #searchButtonCol {
    padding-left: 10px !important; }

.product-item-list #ProductItemList td {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important; }

.product-item-list #ProductItemList .pf-row {
  cursor: pointer; }
  .product-item-list #ProductItemList .pf-row:hover {
    background-color: #cceffc; }

.productItem-pagination {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px; }

#page.productItem-details #top-level-info {
  display: flex;
  flex-direction: row; }

#page.productItem-details .productItem-info {
  overflow: auto;
  flex: 1 1 auto !important;
  width: 100%; }
  #page.productItem-details .productItem-info ul {
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.productItem-details .productItem-info ul li {
      margin: 0.75rem 1.5rem 0.75rem 1.5rem;
      width: 100%;
      margin-bottom: 0.75rem;
      padding-right: 1rem; }
      #page.productItem-details .productItem-info ul li .label {
        font-size: 0.875rem;
        font-weight: 600; }
      #page.productItem-details .productItem-info ul li.stack {
        margin-bottom: 0rem; }

#page.productItem-details .entity-list .row {
  margin: 2rem 0rem; }

#page.productItem-details .entity-list label {
  font-size: 0.875rem;
  font-weight: 600; }

#page.productItem-details .entity-list .scroller {
  margin: 0 0;
  height: 450px;
  width: 100%;
  overflow: scroll; }

#page.productItem-details .entity-list .checkbox {
  margin-right: 10px; }

#page.productItem-details .entity-list .radio {
  margin-right: 10px; }

#page.productItem-details .entity-list .item {
  padding: 10px 10px; }

#page.productItem-details .entity-list .item:nth-child(odd) {
  background-color: #f4f4f4; }

.attribute-schema-list .searchForm {
  background-color: #f1f1f1;
  padding: 35px;
  position: relative; }
  .attribute-schema-list .searchForm button.searchBTN {
    margin-left: 0;
    margin-top: 23px !important; }
  .attribute-schema-list .searchForm #searchButtonCol {
    padding-left: 10px !important; }

.attribute-schema-list #AttributeSchemaList td {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important; }

.attribute-schema-list #AttributeSchemaList .pf-row {
  cursor: pointer; }
  .attribute-schema-list #AttributeSchemaList .pf-row:hover {
    background-color: #cceffc; }

.attributeSchema-pagination {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px; }

#page.attributeSchema-details #top-level-info {
  display: flex;
  flex-direction: row; }

#page.attributeSchema-details .attributeSchema-info {
  overflow: auto;
  flex: 1 1 auto !important;
  width: 100%; }
  #page.attributeSchema-details .attributeSchema-info ul {
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    #page.attributeSchema-details .attributeSchema-info ul li {
      margin: 0.75rem 1.5rem 0.75rem 1.5rem;
      width: 100%;
      margin-bottom: 0.75rem;
      padding-right: 1rem; }
      #page.attributeSchema-details .attributeSchema-info ul li .label {
        font-size: 0.875rem;
        font-weight: 600; }
      #page.attributeSchema-details .attributeSchema-info ul li.stack {
        margin-bottom: 0rem; }

#page.attributeSchema-details .entity-list .row {
  margin: 2rem 0rem; }

#page.attributeSchema-details .entity-list label {
  font-size: 0.875rem;
  font-weight: 600; }

#page.attributeSchema-details .entity-list .scroller {
  margin: 0 0;
  height: 450px;
  width: 100%;
  overflow: scroll; }

#page.attributeSchema-details .entity-list .checkbox {
  margin-right: 10px; }

#page.attributeSchema-details .entity-list .radio {
  margin-right: 10px; }

#page.attributeSchema-details .entity-list .item {
  padding: 10px 10px; }

#page.attributeSchema-details .entity-list .item:nth-child(odd) {
  background-color: #f4f4f4; }

#page.attributeSchema-details .entity-list .scroller {
  height: 500px; }

#page.attributeSchema-details .entity-list .field-type-row {
  padding: 0;
  margin: 1rem 0 1rem 0;
  align-items: center; }

#page.attributeSchema-details .entity-list #entity-buttons,
#page.attributeSchema-details .entity-list .entity-buttons {
  justify-content: space-between;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 1rem;
  margin-top: 0.5rem; }
  #page.attributeSchema-details .entity-list #entity-buttons button,
  #page.attributeSchema-details .entity-list .entity-buttons button {
    padding: 0; }
    #page.attributeSchema-details .entity-list #entity-buttons button:disabled,
    #page.attributeSchema-details .entity-list .entity-buttons button:disabled {
      pointer-events: none; }

#page.attributeSchema-details .entity-list .table-responsive {
  height: 450px;
  overflow-y: scroll; }
  #page.attributeSchema-details .entity-list .table-responsive .entity-table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white; }
  #page.attributeSchema-details .entity-list .table-responsive .entity-table tr.highlight {
    cursor: pointer; }
    #page.attributeSchema-details .entity-list .table-responsive .entity-table tr.highlight:hover {
      background-color: #cceffc; }

/* IE10+ */
/* IE11 */
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  [data-aos^=fade][data-aos^=fade],
  [data-aos^=zoom][data-aos^=zoom] {
    opacity: 1 !important;
    transform: translate(0px, 0px, 0px) !important; } }

